import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react"
import {
    Grid,
    SegmentedControl,
    Select,
    Text,
} from "@mantine/core";
import {useSelector} from "react-redux";
import {useMediaQuery} from "@mantine/hooks";


import TemplateMessage from "./TemplateMessage";
import BasicMessage from "./BasicMessage";
import ButtonsType from "./ButtonType";
import InteractiveMessage from "./InteractiveMessage";
import FormMessage from "./FormMessage";

import {FormatMessagetoEditable} from "../../_Constants/Functions";
import {Page, TextView} from "../index";


const formatMedia = (text) =>{
    if(text?.startsWith("fi_")){
        return {code: text}
    }else if(text?.startsWith("http")){
        return {link: text}
    }else{
        return {id: text}
    }
}
const list = [{label: "Template Message",value: "template"},{label: "Session Message",value: "session"},{label: "Sequence Message",value: "sequence"}];
const MsgTypes = ["Text","Image","Audio","Video","Document","Buttons","Form","List","Url"];

const MessageOptions = forwardRef(({details,show, from}, ref) => {
    const {sessionId,files,sequence} = useSelector(state => state.active);
    const options = show?.length > 0 ? list?.filter(a => show?.includes(a?.value)): list;

    const [type,setType] = useState(details?.type || show?.[0] || "template");
    const [msgType,setMsgType] = useState(details?.msgType ||"Text");
    const [seqId,setSeqId] = useState(details?.seqId || "");
    const [isError,setIsError] = useState(false);
    const [editDetails,setEditDetails] = useState();
    const [render,setRender] = useState(1);
    const [isLoading,setIsLoading] = useState(false);
    const isMobile = useMediaQuery('(max-width: 700px)');

    const ref1 = useRef();

    useEffect(() => {
        if(details){
            setIsLoading(true);
            const formatted = FormatMessagetoEditable({message: details,files});
            setType(formatted.type)
            setMsgType(formatted.msgType)
            setEditDetails(formatted);
            setRender(render+1);
            setIsLoading(false)
        }

    }, [details]);

    useImperativeHandle(ref, () => ({
        isValid() {
            if (type === "template") {
                const details = ref1.current.isValid();
                if(details?.isValid){
                    const {variables, name, language} = details?.message;
                    let vari =  {};
                    variables.map(a => vari[a?.key] = a?.details?.media || a?.value);

                    const components = [];
                    if(vari?.image) components.push({type: "header", parameters:[{type: "image", image:  formatMedia(vari?.image)}]});
                    if(vari?.video) components.push({type: "header", parameters:[{type: "video", video:  formatMedia(vari?.video)}]});
                    if(vari?.document) components.push({type: "header", parameters:[{type: "document", document:  formatMedia(vari?.document)}]});

                    if(vari?.["h_"]) components.push({type: "header", parameters:[{type: "text", text: vari?.["h_"]}]});

                    const body = variables?.filter(a => a?.key?.includes("b_"))?.map(a => ({type: "text", text: a?.value}));
                    if (body?.length > 0) components.push({type: "body", parameters: body});

                    if (details?.message?.buttons?.length > 0) {
                        details?.message?.buttons?.map((a, i) => {
                            switch (a?.type) {
                                case "PHONE_NUMBER":
                                    break;
                                case "URL":
                                    const value = variables?.find(b => b?.key === `buttons_${i}`)?.value;
                                    if (value) {
                                        components.push({
                                            type: "button",
                                            "sub_type": a?.type,
                                            index: i,
                                            ...(value ? {
                                                parameters: [{
                                                    type: "text",
                                                    text: variables?.find(b => b?.key === `buttons_${i}`)?.value
                                                }]
                                            } : {})
                                        })
                                    }
                                    break;
                                case "COPY_CODE":
                                    components.push({
                                        type: "button",
                                        "sub_type": a?.type,
                                        index: i,
                                        parameters: [{
                                            type: "coupon_code",
                                            coupon_code: variables?.find(b => b?.key === `buttons_${i}`)?.value
                                        }]
                                    });
                                    break;
                                case "QUICK_REPLY":
                                    components.push({
                                        type: "button",
                                        "sub_type": "quick_reply",
                                        index: i,
                                        parameters: [{
                                            type: "payload",
                                            payload: variables?.find(b => b?.key === `buttons_${i}`)?.value
                                        }]
                                    });
                                    break;
                                case "FLOW":
                                    components.push({
                                        type: "button",
                                        "sub_type": a?.type,
                                        index: i,
                                        parameters: [
                                            {type: "action", action: {
                                                    "flow_token": "token123",
                                                    "flow_action_data": {
                                                        name: "Jagan"
                                                    }
                                                }}
                                        ]
                                    })
                                    break;
                            }
                        })
                    }
                    const template = {
                        "name": name,
                        "language": {
                            "code": language || "en_US"
                        },
                        components,
                    };
                    return {isValid: true, message: {template,editOptions: {msgType, type, message: details?.message}}}
                }else{
                    return {isValid: false}
                }

            }
            else if(type === "sequence"){
                const isValid = !!seqId
                setIsError(!isValid);
                const name = sequence?.find(a => a?.value === seqId)?.label;
                return {isValid, message: {type, seqId, name,editOptions: {type,seqId,name}}}
            }
            else {
                const {isValid, message} = ref1.current.isValid();
                setIsError(!isValid);
                return {isValid,message, type,msgType}
            }
        }
    }));

    return  <>
        {options?.length > 1 && <Grid.Col span={12}>
            {!isMobile ? <SegmentedControl
                data={options}
                value={type}
                onChange={setType}
                size={"xs"}
            />: <Select
                        data={options}
                        value={type}
                        onChange={setType}
                        size={"xs"}  />}
            {type === "session" &&
                <Text size={"xs"} c={"orange"}>Session Messages Will Deliver to People Who Messaged You in last 24
                    Hours</Text>}
        </Grid.Col>}
        {type === "template" && <TemplateMessage sessionId={sessionId} ref={ref1} details={editDetails} />}
        {type === "session" &&  <>
            <Grid.Col span={12}>
                <Page.ConditionRender
                    isValid={isMobile}
                    success={<Select size={"sm"} label={"Message Type"} data={MsgTypes} value={msgType} onChange={setMsgType} />}
                    fail={<>
                        <TextView.Bold text={"Message Type"} />
                        <SegmentedControl size={"sm"} label={"Message Type"} data={MsgTypes} value={msgType} onChange={setMsgType} />
                    </>}

                />
            </Grid.Col>
            {["Text","Image",'Audio','Video','Document'].includes(msgType) && <BasicMessage type={from} msgType={msgType} ref={ref1}  details={editDetails} />}
            {["Buttons"].includes(msgType) && <ButtonsType type={from}  msgType={msgType} ref={ref1} details={editDetails} />}
            {["Url","List"].includes(msgType) && <InteractiveMessage type={from} msgType={msgType} ref={ref1} details={editDetails} />}
            {["Form"].includes(msgType) && <FormMessage type={from} msgType={msgType} ref={ref1} details={editDetails} />}
        </>}
        {type === "sequence" && <Grid.Col span={12} lg={6} xl={6}>
            <Select size={"xs"} label={"Choose Sequence"} data={sequence} value={seqId} onChange={setSeqId} error={ isError && !seqId ? "Sequence is Required": ""} />
        </Grid.Col>}
    </>
});




export default MessageOptions;
