import {Button, CopyButton, Group} from "@mantine/core";
import React from "react";
import {Icon} from "./Page";
import {CloudApi} from "../_Layouts/RequestManager";
import {Page} from "./index";
import {Colors} from "../_Constants";

export const Submit = ({label, ...props}) =>{
    return <Group mt={25} justify={"center"}>
        <Button w={"50%"} variant={"gradient"} color={Colors.Primary} leftSection={<Icon icon={props?.icon} color={"white"} />} {...props}>{label}</Button>
    </Group>
}
export const Outline = ({label, ...props}) =>{
    return <Button color={Colors.Primary} variant={"outline"} leftSection={<Icon icon={props?.icon} color={Colors.Primary} />} {...props}>{label}</Button>
}
export const Header = ({label, ...props}) =>{
    return <Button color={Colors.Primary} size={"xs"} variant={"filled"} align={"center"} leftSection={<Icon icon={props?.icon} color={"white"} />} {...props}>
        {label}
    </Button>
}
export const Copy = ({value,handler,label,type="button", ...props}) =>{
    return <CopyButton value={value} {...props}>
        {({ copied, copy }) => <>
        {type === "button" ? <Header onClick={copy} {...(copied ? handler?.after: handler?.before)} />:
            <Page.Icon label={label} color={copied ? "green" : Colors.Primary} icon={copied ? "IconCheck" :"IconCopy"} onClick={copy} />}
        </>}
    </CopyButton>
}

export const FlowWebhook = ({id, ...props}) =>{
    return  <Copy value={`${CloudApi.bsmFlowWebhook}/${id}`} handler={{
        after: {label: "Copied Webhook", icon: "IconCheck" ,color: "green"},
        before: {label: "Copy Webhook", icon: "IconCopy" ,color: "blue"},
    }} {...props} />
}