import React, {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import {Grid} from "@mantine/core";
import DocumentPickView from "./DocumentPickView";
import MessagePreview from "../MessagePreview";
import {TextView} from "../index";


const BasicMessage =  forwardRef(({msgType,details}, ref) =>{
    const [message, setMessage] = useState(details?.message);
    const [file,setFile] = useState(details?.file);
    const [isError,setIsError] = useState(false);

    useEffect(() => {
        setFile();
        setMessage();
    }, [msgType]);
    useEffect(() => {
        setFile(details?.file)
        setMessage(details?.message || details?.caption || "");
    }, [details]);

    useImperativeHandle(ref, () => ( {
        isValid() {
            if (msgType === "Text") {
                const isValid = message?.trim() !== ""
                setIsError(!isValid);
                return {isValid, message: {message}}
            } else {
                const isValid = !!file?.file;
                setIsError(!isValid);
                const isUrl = file?.file?.startsWith("http");
                const msg = {message, [msgType?.toLowerCase()]: file?.isCode ? {code: file?.file}: isUrl ? {link: file?.file} : {id: file?.file}};
                return {isValid, message: {...msg}}
            }
        }
    }));
    return <>
        <Grid.Col span={6}>
            {msgType !== "Text" && <DocumentPickView msgType={msgType} file={file} setFile={setFile} />}
            {msgType !== "Audio" && <Grid.Col span={12}>
                <TextView.TextAreaEditor label={"Message"}  text={message || ""} setText={setMessage} limit={4096} minRows={6} />
            </Grid.Col>}
            {isError && <TextView.Error text={"Please Fill All Fields"} align={"center"} />}
        </Grid.Col>
        <Grid.Col span={6}>
            <MessagePreview type={"session"}  message={{message, file, type: msgType}} hideReadmore={true} />
        </Grid.Col>
    </>
});
export default BasicMessage;