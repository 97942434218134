import React, {useCallback, useEffect, useState} from "react";
import {AppShell, Group, Image, ScrollArea, Menu, Avatar, Stack, Text, Divider} from "@mantine/core";
import {useDisclosure} from "@mantine/hooks";
import {useDispatch, useSelector} from "react-redux";
import {IconBook, IconChevronsRight, IconHomeStats, IconUser} from "@tabler/icons-react";
import Navigator from "./_Layouts/Navigation";
import {SocketSetup} from "./_Layouts/Socket";
import {useLocation, useNavigate} from "react-router-dom";
import {ActionUserLogout, DeleteActiveSession, UpdateActiveSession} from "./_Store/action";
import {CallUrl, CloudApi} from "./_Layouts/RequestManager";
import Sidebar from "./_Layouts/Sidebar";
import {Page, ShowPopup, TextView} from "./_Components"
import InternetChange from "./_Layouts/InternetChange";
import {Colors} from "./_Constants";
import {IconGauge, IconLogout, IconMessage, IconSettings, IconUsers} from "@tabler/icons";
import ProfilePage from "./Activities/User/ProfilePage";


const Layout = () =>{
    const [opened, { toggle: toggleDesktop }] = useDisclosure();
    const {isLogged, userId,email} = useSelector(state => state.user);
    const {r365} = useSelector(state => state.active);
    const [isLoading,setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const location = useLocation();
    const navigation = useNavigate();
    let [empty,device,token] = location?.pathname?.split("/");

    useEffect(() =>{
        if(device === "device" && token) FetchDeviceInfo();
        if(device === "device" && token === "undefined"){
            navigation("/")
        }
    },[token]);

    const FetchDeviceInfo = async () =>{
        setIsLoading(true);
        dispatch(DeleteActiveSession());
        await CallUrl({
            type: "GET",
            url: `${CloudApi.deviceDetails}/${userId}/${token}`,
            success: ({device}) =>{
                let info = {...device, connected: {...device?.connected, token: undefined}}
                dispatch(UpdateActiveSession(info));
            },
            error: () =>{
                navigation("/")
            }
        })
        setIsLoading(false);
    }

    return <Page.Loading isLoading={isLoading}>
                <AppShell
                layout="alt"
                navbar={{
                    width: opened? 250 :60,
                    paddingRight: 0,
                    breakpoint: "sm",
                    collapsed: {
                        desktop:  !isLogged || (email !== "admin@magaduka.in" && ["/","/devices","/login"].includes(location?.pathname)),
                        mobile: !isLogged || ["/","/devices","/login"].includes(location?.pathname)
                    }
                }}
                padding="2px"
            >
                <AppShell.Navbar>
                    <AppShell.Section>
                            <Group justify={opened ? "space-around": "center"} onClick={() => navigation("/")} gap={"xs"} style={{cursor: "pointer"}} px={10}>
                                <Image src={require("./_Constants/images/bsm.png")} width={50} height={50} alt={"BSM"} />
                                {opened && <TextView.Header size={"md"} text={"BusinessSalesMachine"} variant={"gradient"} />}
                            </Group>
                    </AppShell.Section>
                        <AppShell.Section m={0} visibleFrom={"md"}>
                            {!opened && <Group justify={opened ? "center" : "center"} bg={Colors.grey} style={{cursor: "pointer"}} onClick={toggleDesktop}
                                    style={{cursor: "pointer"}}>
                                <IconChevronsRight label={"Open"} color={"black"} size={30}/>
                            </Group>}
                            {opened && <Group bg={Colors.grey} position={"center"} style={{cursor: "pointer"}} color={"white"} p={10} onClick={toggleDesktop}>
                                <Page.Icon icon={"IconChevronsLeft"} color={Colors.Primary}/>
                                {opened && <Text c={Colors.Primary}>Close</Text>}
                            </Group>}
                            <Divider />
                        </AppShell.Section>
                        <AppShell.Section component={ScrollArea} mb={5}>
                            <Sidebar opened={opened} />
                        </AppShell.Section>
                        <AppShell.Section h={r365?.productId?.length > 0 ? 150: 70}>
                            {r365?.productId?.length > 0 && <R365Menu opened={opened}/>}
                            <ProfileCard opened={opened} />
                        </AppShell.Section>
                </AppShell.Navbar>
                <AppShell.Main>
                        <InternetChange />
                        <Navigator  />
                    <SocketSetup />
                </AppShell.Main>
            </AppShell>
    </Page.Loading>
}

export default  Layout;


const ProfileCard = ({opened}) =>{
    const [isOpenProfile,setIsProfileOpen] = useState(false);
    const {name,email} = useSelector(state => state.user);
    const dispatch= useDispatch()
    const navigation= useNavigate();
    const handleLogout = useCallback(() =>{
        ShowPopup({  title: "Logout",
            content: "Are you sure want to Logout ?",
            successTitle: "Logout",
            cancelTitle: "Cancel",
            onSuccess: () =>{
                dispatch(ActionUserLogout());
                navigation("/")
            }})
    },[]);

    return <>
        {isOpenProfile && <ProfilePage onClose={() => setIsProfileOpen(false)} />}
        <Menu shadow="md" width={250} position={"right"}>
        <Menu.Target>
            <Group justify={opened ? "flex-start":"center"} style={{cursor: "pointer"}}>
                <Avatar  color={Colors.Primary} radius={"md"} name={name?.[0]} />
                {opened && <Stack style={{gap: 1}}>
                    <TextView.Bold text={name} />
                    <TextView.Small text={email} />
                </Stack>}
            </Group>
        </Menu.Target>
        <Menu.Dropdown>
            <Menu.Label bg={Colors.Primary}>
                <Group justify={"flex-start"} style={{cursor: "pointer"}}>
                    <Avatar bg={"white"} color={Colors.Primary} radius={"md"} name={name?.[0]} />
                    <Stack gap={"xs"}>
                        <TextView.Bold c={"white"} text={name} />
                        <TextView.Small c={"white"} text={email} />
                    </Stack>
                </Group>
            </Menu.Label>
            <Menu.Item leftSection={<IconUser size={14} />} onClick={() => setIsProfileOpen(true)}>View Profile</Menu.Item>
            <Menu.Item leftSection={<IconLogout size={14} />}  color="red" onClick={handleLogout}>Logout</Menu.Item>
        </Menu.Dropdown>
    </Menu>
        </>
}
const R365Menu = ({opened}) =>{
    const {alias} = useSelector(state => state.active);
    const [isOpenProfile,setIsProfileOpen] = useState(false);
    const navigate= useNavigate();

    return <>
        {isOpenProfile && <ProfilePage onClose={() => setIsProfileOpen(false)} />}
        <Menu shadow="md" width={250} position={"right"} trigger={"hover"}>
            <Menu.Target>
                <Group align={"center"} justify={"center"} my={5}>
                    <IconBook />
                </Group>
            </Menu.Target>
            <Menu.Dropdown>
                <Menu.Label bg={Colors.Primary}>
                    <TextView.Bold text={"Read365"} c={"white"} />
                </Menu.Label>
                <Menu.Item leftSection={<IconGauge />} onClick={() => navigate(`device/${alias}/r365`)}>Dashboard</Menu.Item>
                <Menu.Item leftSection={<IconBook />} onClick={() => navigate(`device/${alias}/r365/books`)}>Books</Menu.Item>
                <Menu.Item leftSection={<IconUsers />} onClick={() => navigate(`device/${alias}/r365/customers`)}>Customers</Menu.Item>
                <Menu.Item leftSection={<IconMessage />} onClick={() => navigate(`device/${alias}/r365/messages`)}>Today Messages</Menu.Item>
                <Menu.Item leftSection={<IconSettings />} onClick={() => navigate(`device/${alias}/r365/actions`)}>Actions</Menu.Item>
                <Menu.Item leftSection={<IconHomeStats />} onClick={() => navigate(`device/${alias}/r365/Stats`)}>Stats</Menu.Item>
            </Menu.Dropdown>
    </Menu>
        </>
}
