import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {CallUrl, CloudApi} from "../../_Layouts/RequestManager";
import {Image, Loader} from "@mantine/core";
import {Page, TextView} from "../index";

const FileViewOptions = ({file,type, size}) =>{
    const [details,setDetails] = useState();
    const [isLoading,setIsLoading] = useState(false);
    const {sessionId} = useSelector(state => state.active);

    const formatFile = async (file) =>{
        if(typeof file === "object"){
            if(!!file?.code){
                setIsLoading(true);
                await CallUrl({
                    type: "GET",
                    url: `${CloudApi.mediFileDetails}/${sessionId}/${file?.code}`,
                    success: (details) =>{
                        setDetails(details?.mediaLink ? {link: details?.mediaLink} : details?.mediaId ? {id: details?.mediaId}: undefined)
                    }
                })
                setIsLoading(false);
            }else{
                setDetails(file);
            }

        }else{
            if(file?.startsWith("fi_")){
                if(!!file?.code){
                    setIsLoading(true);
                    await CallUrl({
                        type: "GET",
                        url: `${CloudApi.mediFileDetails}/${sessionId}/${file?.code}`,
                        success: (details) =>{
                            setDetails(details?.mediaLink ? {link: details?.mediaLink} : details?.mediaId ? {id: details?.mediaId}: undefined)
                        }
                    })
                    setIsLoading(false);
                }

                setDetails({code: file})
            }else if(file?.startsWith("http")){
                setDetails({link: file})
            }else{
                setDetails({id: file})
            }
        }

    }
    useEffect( () => {
        formatFile(file)
    }, [file]);
    const DownloadMedia = (id) =>{
        window.open(`${CloudApi.mediaIdDownload}/${sessionId}/${id}`)
    }

    return <>
        {isLoading ? <Loader />: <>
            {type?.toLowerCase() === "image" && details?.link ? <Page.GroupGap align={"center"}>
                {file?.link && <Image src={details?.link} h={size || 100}  w="auto" fit={"contain"}/>}
            </Page.GroupGap>: <Page.Card style={{backgroundColor: "#ccc"}}>
                <Page.GroupApart style={{backgroundColor: "#ccc"}}>
                    <TextView.Bold text={type?.toUpperCase()} />
                    {details?.id && <Page.Icon icon={"IconDownload"} onClick={() => DownloadMedia(details?.id)} />}
                </Page.GroupApart>
            </Page.Card>}
        </>}
    </>
}
export default FileViewOptions;