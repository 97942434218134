import {useState} from "react";
import {useSelector} from "react-redux";
import {Select} from "@mantine/core";
import {Buttons, Page} from "../index";

const AddVariables = ({onClose, onSave}) => {
    const {params} = useSelector(state => state.active);
    const [selected,setSelected] = useState();

    return <Page.ModalSetup onClose={onClose} opened={true} title={"Add Variables"}>
        <Select size={"xs"} my={10} label={"Choose Variable"} value={selected} onChange={setSelected} data={params} />
        <Buttons.Submit label={"Save"}  onClick={() =>{
            onSave(`{${selected}}`);
            onClose()
        }} />
    </Page.ModalSetup>
}
export default AddVariables;