import {Accordion, Grid, Group, Button, Stack, Badge, Avatar} from "@mantine/core";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import moment from "moment";
import React from "react";
import {useNavigate} from "react-router-dom";
import {CallUrl, CloudApi} from "../../_Layouts/RequestManager";
import {Buttons, Inputs, Page, Notification, TextView} from "../../_Components";

const ProfilePage = ({onClose}) =>{
    const {userId} = useSelector(state => state.user);
    const [isLoading,setIsLoading] = useState(false);
    const [profile,setProfile] = useState();
    const [sessions,setSessions] = useState([]);
    const [isEditProfile,setIsEditProfile] = useState(false);
    const [isChangePwd,setIsChangePwd] = useState(false);
    const navigation = useNavigate();

    useEffect(() => {
        getDetails();
    },[])
    const getDetails = async () =>{
        setIsLoading(true);
        await CallUrl({
            type: "POST",
            url: `${CloudApi.UserProfile}/details/${userId}`,
            body: {},
            success: ({user,sessions=[]}) =>{
                setProfile(user);
                setSessions(sessions || []);
            }
        });
        setIsLoading(false);
    }
    const handlePlanUpdate = () => {
        onClose();
        navigation("/update")
    }



    return <Page.DrawerSetup onClose={onClose} position={"right"} size={"50%"}>
        <Page.Loading isLoading={isLoading}>
            {isEditProfile && <EditProfile onRefresh={getDetails} userId={userId} profile={profile}  onClose={() => setIsEditProfile(false)} />}
            {isChangePwd && <ChangePassword onRefresh={getDetails} userId={userId} onClose={() => setIsChangePwd(false)} />}
            <Page.Card mx={10}>
                <Stack>
                    <Page.GroupApart>
                        <Page.GroupGap>
                            <Avatar size={"lg"} name={profile?.name?.[0]} />
                            <Stack gap={"xs"}>
                                <TextView.Bold text={profile?.name} />
                                <TextView.Small text={profile?.email} />
                            </Stack>
                        </Page.GroupGap>
                        <Stack gap={"xs"}>
                            <Page.GroupGap justify={"flex-end"}>
                                <Page.Icon icon={"IconEdit"} onClick={() => setIsEditProfile(true)} />
                            </Page.GroupGap>
                            <Page.IconText icon={"IconPhone"}  text={profile?.mobile} />
                        </Stack>
                    </Page.GroupApart>
                </Stack>
            </Page.Card>
            <Button compact onClick={() => setIsChangePwd(true)} mx={10} my={5}>Change Password</Button>
            {/*<Button compact onClick={handlePlanUpdate} mx={10} my={5}>Upgrade Plan </Button>*/}
            <Accordion>
                {sessions?.map(a =>{
                    return  <Accordion.Item value={a?.sessionId}>
                    <Accordion.Control>
                        <Page.GroupApart>
                            {a?.name}
                            <Group>
                                {a?.expiry && <TextView.Small text={`Expires  ${moment.unix(a?.expiry).fromNow()}`}/>}
                                {a?.activePlans?.length > 0 ? <Badge>{a?.activePlans}</Badge>: <Badge color={"red"}>No Active Plans</Badge>}
                            </Group>
                        </Page.GroupApart>

                    </Accordion.Control>
                        <Accordion.Panel>
                            <PrefCard prefrences={a?.prefrences} prefUsed={a?.prefUsed} />
                        </Accordion.Panel>
                    </Accordion.Item>
                })}
            </Accordion>
        </Page.Loading>
    </Page.DrawerSetup>
}

export default ProfilePage;

const EditProfile = ({profile,onClose,userId,onRefresh}) =>{
    const [name,setName] = useState(profile?.name || "");
    const [mobile,setMobile] = useState(profile?.mobile ||"");
    const [isLoading,setIsLoading] = useState(false);
    const [isError,setIsError] = useState(false);

    const UpdateProfile = async () =>{
        if(name?.trim() !== "" && mobile?.trim() !== ""){
            setIsError(false);
            setIsLoading(true);
            await CallUrl({
                type: "POST",
                url: `${CloudApi.UserProfile}/updateDetails/${userId}`,
                body: {name,mobile},
                success: ({message}) =>{
                    Notification.Show({message});
                    onClose();
                    onRefresh();
                }
            });
            setIsLoading(false);
        }else{
            setIsError(true);
        }
    }

    return <Page.ModalSetup  onClose={onClose} title={"Edit Profile"} >
        <Stack gap={"xs"}>
            <Inputs.Text label={"Name"} value={name} onChange={setName} error={isError && name?.trim() === "" ? "Name is Required":""} />
            <Inputs.Text label={"Mobile"} value={mobile}  onChange={setMobile} error={isError && mobile?.trim() === "" ? "Mobile is Required":""} />
            <Buttons.Submit label={"Update Profile"} onClick={UpdateProfile} loading={isLoading} />
        </Stack>
    </Page.ModalSetup>
}
const ChangePassword = ({onClose,userId,onRefresh}) =>{
    const [password,setPassword] = useState( "");
    const [confirmPwd,setConfirmPwd] = useState("");

    const [isLoading,setIsLoading] = useState(false);
    const [isError,setIsError] = useState(false);

    const ChangePassword = async () =>{
        if(password?.trim() !== "" && confirmPwd?.trim() !== "" && password === confirmPwd){
            setIsError(false);
            setIsLoading(true);
            await CallUrl({
                type: "POST",
                url: `${CloudApi.UserProfile}/changePassword/${userId}`,
                body: {password},
                success: ({message}) =>{
                    onClose();
                    onRefresh();
                    Notification.Show({message});
                }
            });
            setIsLoading(false);
        }else{
            setIsError(true);
        }
    }


    return <Page.ModalSetup onClose={onClose} title={"Change Password"}>
        <Stack  gap={"xs"}>
            <Inputs.Text label={"Password"} value={password} onChange={setPassword} error={isError && password?.trim() === "" ? "Password is Required":""} />
            <Inputs.Text label={"Confirm Password"} value={confirmPwd} onChange={setConfirmPwd} error={isError && confirmPwd?.trim() === "" ? "Password is Required": isError && confirmPwd?.trim() !== password?.trim()  ? "Passwords Not Matched":""} />
            <Buttons.Submit  loading={isLoading} onClick={ChangePassword} label={"Update password"} />
        </Stack>
    </Page.ModalSetup>
}

const PrefCard = ({prefrences, prefUsed}) => {
    const data = [
        // {label: "Total Days",total: user?.totalDays || 0,value: user?.day,mul: 1},
        {label: "Chatbot",total: prefrences?.chatbots || 0,value: prefUsed?.chatbots || 0,mul: 1},
        {label: "Sequence",total: prefrences?.sequence || 0,value: prefUsed?.sequence || 0,mul: 1},
        {label: "Folders",total: prefrences?.attachments || 0,value: prefUsed?.attachments || 0,mul: 1},
        {label: "Files",total: prefrences?.files || 0,value: prefUsed?.files || 0,mul: 1},
        {label: "Programs",total: prefrences?.programs || 0,value: prefUsed?.programs || 0,mul: 1},
        {label: "Forms",total: prefrences?.forms || 0,value: prefUsed?.forms || 0,mul: 1},

    ];
    const calculatePer = ({total,value,mul}) =>{
        return Math.ceil((value / (total * mul))* 100);
    }
    return  <Grid>
        {data?.map(a =>(
            <Grid.Col span={6}>
                <Page.Card>
                    <Page.GroupApart>
                        <TextView.Bold text={a?.label}  />
                        <Badge>{a?.value}/{a?.total}</Badge>
                    </Page.GroupApart>
                </Page.Card>
            </Grid.Col>
        ))}
    </Grid>
}