import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";
import {useSelector} from "react-redux";
import {CallUrl, CloudApi} from "../../_Layouts/RequestManager";
import {Grid, Group, Loader, Select, Text} from "@mantine/core";
import DocumentPickView from "./DocumentPickView";
import MessagePreview from "../MessagePreview";
import {Buttons, Inputs, Page, TextView} from "../index";

const FormMessage = forwardRef((props, ref)=> {
    const {msgType,details} = props;
    const {forms,sessionId} = useSelector(state => state.active);
    const ref1 = useRef();

    const [headerText,setHeaderText] = useState(details?.headerText || "");
    const [body,setBody] = useState(details?.body || "");
    const [footer,setFooter] = useState(details?.footer || "");
    const [formId,setFormId] = useState(details?.formId || "");
    const [screenId,setScreenId] = useState(details?.screenId || "");
    const [btnText,setBtnText] = useState(details?.btnText || "");

    const [headerType,setHeaderType] = useState(details?.headerType || "NONE");
    const [headerFile,setHeaderFile] = useState(details?.file);
    const [payload,setPayload] = useState(details?.payload || []);


    const [selected,setSelected] = useState({});
    const [isScreenLoad,setIsScreenLoad] = useState(false);
    const [isError,setIsError] = useState(false);

    const [screens,setScreens] = useState([]);
    const [render,setRender] = useState(0);

    useEffect(() =>{
        setSelected(forms?.find(a => a?.value === formId));
        setHeaderFile(details?.file);
        if(formId) getFlowScreens(formId);
    },[formId])
    const getFlowScreens = async (flowId) =>{
        setIsScreenLoad(true)
        await CallUrl({
            type: "GET",
            url: `${CloudApi.whatsappFlows}/screens/${sessionId}/${flowId}`,
            success: (data) =>{
                setScreens(data?.screens || []);
            }
        })
        setHeaderFile(details?.file);
        setRender(render+1);
        setIsScreenLoad(false)
    }
    useImperativeHandle(ref, () => ( {
        isValid() {
            const {file, isCode} = ref1.current?.isValid() || {};
            let header = {type: headerType};
            if(headerType === "NONE") {
                header = undefined;
            }else if(headerType === "TEXT") {
                header = {...header, text: headerText};
            }else{
                const isUrl = headerFile?.file?.startsWith("http");
                header = {...header, [headerType?.toLowerCase()]:headerFile?.isCode ? {code: headerFile?.file}: isUrl ? {link: headerFile?.file} : {id: headerFile?.file}}
            }

            let payloadFormat = {};
            payload.map(a => payloadFormat[a?.key] = a?.value);

            if(formId && screenId && selected?.status !== "PUBLISHED"){
                let message = {draftForm: {formId,screenId,name: selected?.label, payload: payloadFormat}};
                let msgDetails = {formId,screenId,name: selected?.label, payload};
                setIsError(false);
                return {message,msgDetails, isValid: true };
            }else if(formId && screenId && selected?.status === "PUBLISHED"){
                let message = {form: {formId,screenId,name: selected?.label},header,body,footer,btnText,file,payload: payloadFormat};
                let msgDetails = {formId,screenId,headerText,headerFile,headerType,body,footer,btnText,name: selected?.label,file,payload};
                const isValid =  body?.trim() !== "" && btnText?.trim() !== "";
                setIsError(!isValid);
                return {message,msgDetails, isValid};
            }else{
                setIsError(true);
                let message = {formId,screenId,header,body,footer,btnText,file,payload: payloadFormat};
                let msgDetails = {formId,screenId,headerFile,headerText,headerType,body,footer,btnText,file,payload};
                return {message,msgDetails, isValid: false };
            }
        }
    }));
    const handleValues = (key,value,index) =>{
        const b = payload?.map((a,i) => {
            if(i === index) return {...a,[key]: value};
            return a;
        });
        setPayload(b);
        setRender(render+1)
    }

    return  <>
        <Grid.Col span={6}>
            <Group>
                <Grid.Col span={5}>
                    <Select required size={"xs"} label={"Choose Form"} data={forms} value={formId} onChange={setFormId} error={isError && !formId ? "Please Select Form": ""}  />
                </Grid.Col>
                {formId && <Grid.Col span={5}>
                    {isScreenLoad ? <Loader size={"xs"} mt={20}/> :
                        <Select required size={"xs"} label={"Choose Screen"} data={screens} value={screenId} onChange={setScreenId} error={isError && !screenId ? "Please Select Screen": ""} />}
                </Grid.Col>}
            </Group>

            {formId && selected?.status === "PUBLISHED" ? <>
                <Grid.Col span={12}>
                    <Page.GroupGap style={{gap: 5}}>
                        <TextView.Bold size={"md"}  text={"Header"} />
                        <Select size={"xs"} data={["NONE","TEXT","IMAGE","VIDEO","DOCUMENT"]} value={headerType}  onChange={setHeaderType}/>
                    </Page.GroupGap>
                </Grid.Col>
                <Grid.Col span={12}>
                    {headerType === "TEXT" && <Inputs.Text label={"Header Message"} value={headerText} onChange={setHeaderText} limit={60}/>}
                </Grid.Col>
                {!["NONE","TEXT"]?.includes(headerType) && <DocumentPickView msgType={headerType} file={headerFile} setFile={setHeaderFile} />}
                <Grid.Col span={12}>
                    <TextView.TextAreaEditor label={"Body Message"} required  text={body} setText={setBody} error={isError && body?.trim() === "" ? "Body Message is Required": ""} />
                </Grid.Col>
                <Grid.Col span={12}>
                    <Inputs.Text  label={"Footer Message"} value={footer} onChange={setFooter} />
                </Grid.Col>
                <Grid.Col span={12}>
                    <Inputs.Text required  label={"Button Text"} value={btnText} onChange={setBtnText} error={isError && btnText?.trim() === "" ? "Button text is Required": ""} />
                </Grid.Col>
            </>: formId && <Grid.Col span={12}>
                <TextView.Error size={"xs"} text={`Form not in Published state. so default message will be sent`} />
            </Grid.Col>}
            <Grid.Col span={12}>
                <Group>
                    <Text weight={"bold"} variant={"gradient"} size={"md"}>Payload</Text>
                    <Buttons.Header label={"Add Payload"} compact onClick={() => payload?.length < 10 ? setPayload([...payload,{key: "",value: ""}]): {}} />
                </Group>
            </Grid.Col>
            {payload?.map((a,i) =>{
                return <Grid.Col span={12}>
                    <Group>
                        <Inputs.Text label={"Payload Key"} value={a?.key} onChange={(value) => handleValues("key",value,i)} limit={20} />
                        <Inputs.Text label={"Payload Value"} value={a?.value} onChange={(value) => handleValues("value",value,i)} limit={256} />
                        {payload?.length > 1 && <Page.Icon icon={"IconTrash"} color={"red"} onClick={() => setPayload(payload?.filter((a,index) => i !== index))} />}
                    </Group>
                </Grid.Col>
            })}
        </Grid.Col>
        <Grid.Col span={6}>
            <MessagePreview type={"session"} message={{headerType,headerText, headerFile,body,footer,btnText, type: msgType}} />
        </Grid.Col>

    </>
});
export default FormMessage;