import React, {lazy} from "react";
import {useSelector} from "react-redux";
import {Navigate, Outlet, Route, Routes} from "react-router-dom";

const  AddWBA =lazy(() => import("../Activities/User/AddWBA"));
const  Login =lazy(() => import("../Activities/User/Login"));
const  DeviceList = lazy(() => import("../Activities/User/DeviceList"));
const Dashboard  = lazy(() => import("../Activities/Dashboard/Dashboard"));
const Folders  = lazy(() => import("../Activities/Attachments/Folders"));
const ApprovedTemplates  = lazy(() => import("../Activities/Templates/ApprovedTemplates"));
const Forms = lazy(() => import("../Activities/Forms/Forms"));
const FormUpdate  = lazy(() => import( "../Activities/Forms/FormUpdate"));
const MessageList  = lazy(() => import( "../Activities/MessageList/MessageList"));
const Settings  = lazy(() => import( "../Activities/Settings/Settings"));
const Chatbot  = lazy(() => import( "../Activities/ChatBot/ChatBot"));
const ChatbotLeads  = lazy(() => import( "../Activities/ChatBot/ChatbotLeads"));
const ProgramDetails  = lazy(() => import( "../Activities/Programs/ProgramDetails"));
const AddBulkMembers  = lazy(() => import( "../Activities/Programs/AddBulkMembers"));
const Programs  = lazy(() => import( "../Activities/Programs/Programs"));
const Inbox  = lazy(() => import( "../Activities/Inbox/Inbox"));
const CRMList  = lazy(() => import( "../Activities/CRM/CRMList"));
const Sequence  = lazy(() => import( "../Activities/Sequence/Sequence"));
const SequenceEdit  = lazy(() => import( "../Activities/Sequence/SequenceEdit"));
const SequenceLeads  = lazy(() => import( "../Activities/Sequence/SequenceLeads"));
const CreateMessage  = lazy(() => import( "../Activities/Schedule/CreateMessage"));
const ScheduleList  = lazy(() => import( "../Activities/Schedule/ScheduleList"));
const MessageLogs  = lazy(() => import( "../Activities/Logs/MessageLogs"));
const Flows  = lazy(() => import( "../Activities/Flows"));
const EditFlow  = lazy(() => import( "../Activities/Flows/EditFlow"));
const FlowLogs  = lazy(() => import( "../Activities/Flows/FlowLogs"));

const AdminDashboard  = lazy(() => import( "../Activities/Admin/Dashboard"));
const AdminSessions  = lazy(() => import( "../Activities/Admin/Sessions/Sessions"));
const Payments  = lazy(() => import( "../Activities/Admin/Payments"));
const PayLinks  = lazy(() => import( "../Activities/Admin/PayLinks"));
const PlanUpdate  = lazy(() => import( "../Activities/Admin/PlanUpdatePage"));
const Users  = lazy(() => import( "../Activities/Admin/Sessions/Users"));
const AdminNotifications  = lazy(() => import( "../Activities/Admin/Notifications/lists"));


const R365Dashboard  = lazy(() => import( "../Activities/Read365/R365Dashboard"));
const CustomerMessages  = lazy(() => import( "../Activities/Read365/Customers/CustomerMessages"));
const R365UserStats  = lazy(() => import( "../Activities/Read365/Stats/R365UserStats"));
const Books  = lazy(() => import( "../Activities/Read365/Books/Books"));
const Actions  = lazy(() => import( "../Activities/Read365/Misc/Actions"));
const Customers  = lazy(() => import( "../Activities/Read365/Customers/Cusotmers"));
const CustomerDetails  = lazy(() => import( "../Activities/Read365/Customers/CustomerDetails"));

const paths =[
    {path: `/`,component: <Dashboard />},
    {path: `/templates`,component: <ApprovedTemplates />},
    {path: `/inbox`,component: <Inbox />},
    {path: `/schedule-message`,component: <CreateMessage />},
    {path: `/schedules`,component: <ScheduleList />},
    {path: `/logs`,component: <MessageLogs />},
    {path: `/forms`,component: <Forms />},
    {path: `/form-edit`,component: <FormUpdate />},
    {path: `/chatbots`,component: <Chatbot />},
    {path: `/chat-leads`,component: <ChatbotLeads />},
    {path: `/sequence`,component: <Sequence />},
    {path: `/sequence-edit/:id`,component: <SequenceEdit />},
    {path: `/sequence-leads/:id`,component: <SequenceLeads />},
    {path: `/message-list`,component: <MessageList />},
    {path: `/settings`,component: <Settings />},
    {path: `/programs`,component: <Programs />},
    {path: `/programs/view`,component: <ProgramDetails />},
    {path: `/programs/add-members`,component: <AddBulkMembers />},
    {path: `/flows`,component: <Flows />},
    {path: `/flows/edit/:id`,component: <EditFlow />},
    {path: `/flows/logs/:id`,component: <FlowLogs />},
    {path: `/attachments`,component: <Folders />},
    {path: `/crm`,component: <CRMList />},

    // {path: `/tracker`,component: <Tracker />},
    // {path: `/tracker-details/:id`,component: <TrackerDetails />},
];
const r365Paths = [
    {path: `/r365`,component: <R365Dashboard />},
    {path: `/r365/books`,component: <Books />},
    {path: `/r365/customers`,component: <Customers />},
    {path: `/r365/customer-details/:mobile`,component: <CustomerDetails />},
    {path: `/r365/actions`,component: <Actions />},
    {path: `/r365/stats`,component: <R365UserStats />},
    {path: `/r365/messages`,component: <CustomerMessages />},
]
const AdminPaths =[
    {path: `/`,component: <AdminDashboard />},
    {path: `/sessions`,component: <AdminSessions />},
    {path: `/users`,component: <Users />},
    {path: `/notifications`,component: <AdminNotifications />},
    {path: `/payments`,component: <Payments />},
    {path: `/paylinks`,component: <PayLinks />},
    {path: `/update-plan`,component: <PlanUpdate />},
]



const Navigator = () =>{
    const {alias,r365} = useSelector(state => state.active);
    const {email} = useSelector(state => state.user);
    console.log("dsf", r365)
    return  <Routes>
        <Route exact path="/login" element={<PublicOutlet />}>
            <Route path="" element={<Login />}/>
        </Route>

        {email === "admin@magaduka.in" ? <>
            {AdminPaths.map(path =><Route exact path={`/${path?.path}`} element={<PrivateOutlet />}>
                <Route path="" element={path?.component} />
            </Route>)}
        </> : <>
            <Route exact path={`/`} element={<PrivateOutlet />}>
                <Route path="" element={<DeviceList />} />
            </Route>
            {paths?.map(path =><Route exact path={`/device/${alias}${path?.path}`} element={path?.path === "/" ? <PrivateOutlet />: <SessionOutlet />}>
                <Route path="" element={path?.component} />
            </Route>)}
            {r365?.productId?.length > 0 && r365Paths?.map(path =><Route exact path={`/device/${alias}${path?.path}`} element={<SessionOutlet />}>
                <Route path="" element={path?.component} />
            </Route>)}
        </>}

        <Route path="/facebook" element={<AddWBA />}/>
        <Route path="/facebook/:type" element={<AddWBA />}/>
    </Routes>
}
function SessionOutlet() {
    const {isLogged} = useSelector(state => state.user);
    const {connected} = useSelector(state => state.active);
    return  isLogged && connected?.whatsappId ? <Outlet /> : isLogged ? <Navigate to={`/not-connected`} />: <Navigate to="/login" />
}
function PrivateOutlet() {
    const {isLogged,userId} = useSelector(state => state.user);
    return isLogged && userId ? <Outlet /> : <Navigate to="/login" />;
}
function PublicOutlet() {
    const {isLogged,userId} = useSelector(state => state.user);
    return isLogged && userId ? <Navigate to={'/'} /> : <Outlet />;
}

export default Navigator;
