import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import '@mantine/notifications/styles.css';

import React, {Suspense} from 'react';
import {BrowserRouter} from "react-router-dom";
import {Provider} from 'react-redux';
import {persistStore} from "redux-persist";
import {PersistGate} from "redux-persist/integration/react";
import {Loader, MantineProvider} from "@mantine/core";

import {Colors} from "./_Constants";
import history from "./_Layouts/history";
import Layout from "./layout";
import store from "./_Store/store";

import { createRoot } from 'react-dom/client';
import {Notifications} from "@mantine/notifications";
import { ModalsProvider } from '@mantine/modals';

const persistor = persistStore(store);
const theme  = {
    colorScheme: 'light',
    loader: 'bars',
    primaryColor: 'violet',
    defaultGradient:{
        from: '#ff7f50',
        to: Colors.Primary,
        deg: 45
    },
    colors:{
        default: ['red',Colors.Primary]
    },
    Component: {
        Stack:{
            defaultProps: {
               gap: 3
            },
        },
        Group:{
            defaultProps: {
                gap: 3,
                justify: 'space-between'
            },
        },
    }
}
const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript

root.render( <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter history={history}>
            <MantineProvider withGlobalStyles withNormalizeCSS theme={theme}>
                <ModalsProvider>
                    <Notifications  autoClose={3000} limit={5} />
                    <Suspense fallback={<Loader type={"bars"} style={(theme) => ({ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"})} />}>
                        <Layout />
                    </Suspense>
                </ModalsProvider>
            </MantineProvider>
        </BrowserRouter>
    </PersistGate>
</Provider>);

