import {ActionIcon, Group, PasswordInput, Select, Stack, Textarea, TextInput} from "@mantine/core";
import {Label} from "./Page";
import {
    IconCalendar,
    IconCircle,
    IconCircleCheck,
    IconSquare,
    IconSquareCheck,
    IconToggleLeft,
    IconToggleRight
} from "@tabler/icons";
import {Colors} from "../_Constants";
import React from "react";
import {DatePickerInput, DateTimePicker, TimeInput} from "@mantine/dates";
import {TextView} from "./index";
import {getIcons} from "../_Constants/Functions";
import moment from "moment";

export const Text = ({label, value,limit, icon,required,onChange, ...props})=>{
    return  <Stack style={{gap: 1}}>
        {label && <Label label={label} value={value} limit={limit} required={required} />}
        <TextInput leftSection={getIcons(icon, {size: 16})}  size={"xs"} variant={"filled"} value={value} onChange={e => onChange(limit? e.target.value?.slice(0,limit): e.target.value)} {...props} />
    </Stack>
}
export const TextArea = ({label, value,limit, required,onChange, ...props})=>{
    return <Stack style={{gap: 1}}>
        {label && <Label label={label} value={value} limit={limit} required={required} />}
        <Textarea size={"xs"} value={value}  variant={"filled"} {...props} onChange={e => onChange(e.target.value)} />
    </Stack>
}
export const Password = ({label, value,limit, required,onChange, ...props})=>{
    return <Stack style={{gap: 1}}>
        {label && <Label label={label} value={value} limit={limit} required={required} />}
        <PasswordInput  size={"xs"} variant={"filled"} {...props} onChange={e => onChange(e.target.value)} />
    </Stack>
}
export const CheckBox = ({isSelected,text,onClick, size}) =>{
    return <Group onClick={() => onClick(!isSelected)} style={{cursor: "pointer",gap: 3}}>
        {isSelected ? <IconSquareCheck size={size || 20} /> : <IconSquare size={size || 20} />}
        {text && <TextView.Small c={"black"} text={text} size={"sm"} />}
    </Group>
}
export const Radio = ({isSelected,text,onClick}) =>{
    return <Group onClick={() => onClick(!isSelected)} style={{cursor: "pointer",gap: 3}}>
        {isSelected ? <IconCircleCheck size={20} /> : <IconCircle size={20} />}
        {text && <TextView.Small c={"black"} text={text} size={"sm"}  />}
    </Group>
}
export const Switch  = ({isSelected, text,onClick,onEdit}) =>{
    return <Group position={'apart'} onClick={onEdit} sx={{cursor: "pointer",color: Colors.Secondary}}>
        {text && <TextView.Bold  text={text} mx={5} />}
        <ActionIcon variant={'subtle'} sx={{backgroundColor: Colors.Secondary}} onClick={(e) => {
            e.stopPropagation();
            onClick(!isSelected);
        }}>
            {isSelected ? <IconToggleRight color={'green'} /> : <IconToggleLeft color={'red'} />}
        </ActionIcon>
    </Group>
}

export const Date = ({...props}) =>{
    return <DatePickerInput
        miw={150}
        leftSection={<IconCalendar size={18} stroke={1.5} />}
        size={"xs"}
        {...props}
    />
}
export const DateTime = ({...props}) =>{
    return <DateTimePicker
        miw={150}
        leftSection={<IconCalendar size={18} stroke={1.5} />}
        size={"xs"}
        {...props}
    />
}
export const Time = ({onChange,...props}) =>{
    return <TimeInput
        size={"xs"}
        miw={150}
        // withSeconds
        onChange={(e) => {
            onChange(e?.target.value)
        }}
        {...props}
    />
}

export const SelectItem = ({iconProps,...props}) => {
    const renderSelectOption= ({ option, checked }) => (<Group  flex="1" justify={"space-between"}>
            {/*<Group gap="xs">*/}
                {option.label}
                {/*{checked && <IconCheck style={{ marginInlineStart: 'auto' }} {...iconProps} />}*/}
            {/*</Group>*/}
           {option?.count && <Text c={"dimmed"}>{option?.count}</Text>}
        </Group>)
   return  <Select allowDeselect={false} {...props} renderOption={renderSelectOption} />
}