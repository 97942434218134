import {notifications} from "@mantine/notifications";
import classes from '../_Constants/notification.module.css';

import * as Buttons from "./Buttons";
import * as Inputs from "./Inputs";
import * as Page from "./Page";
import * as TextView from "./TextView";
import * as Msg from "./MessageComponents/index";

import PageLayout from "./PageLayout";
import MessagePreview from "./MessagePreview";
import AssignTagsCRM from "./AssignTagsCRM";
import {modals} from "@mantine/modals";
import {Text} from "@mantine/core";


const ShowNotification = ({message, color= "green", ...props}) =>{
    if(!message) return;
   return notifications.show({
        message,
        color: color,
        classNames: classes,
        position: "top-right",
        autoClose: 2000,
        ...props,
    })
}
const Loading = ({message, color= "green", ...props}) =>{
    return notifications.show({
        message,
        loading: true,
        color: color,
        position: "top-right",
        autoClose: false,
        withCloseButton: false,
        ...props,
    })
}
const Update = ({id,message, color= "green", ...props}) =>{
    return notifications.update({
        id,
        message,
        color: color,
        loading: false,
        // classNames: classes,
        position: "top-right",
        autoClose: 2000,
        withCloseButton: true,
        ...props,
    })
}
const Hide = ({id,message, color= "green", ...props}) =>{
    return notifications.hide(id)
}

const ShowPopup = ({title,content,successTitle,cancelTitle,onSuccess, onCancel, ...props}) => {
    return modals.openConfirmModal({
        title,
        children: (
            <Text size="sm">{content}</Text>
        ),
        labels: { confirm: successTitle, cancel: cancelTitle },
        onCancel: onCancel,
        onConfirm: onSuccess,
        ...props
    });
};

const Notification = {Show: ShowNotification,Loading,Update,Hide}
export {Buttons,Inputs,Page,TextView,Msg,PageLayout,MessagePreview,AssignTagsCRM, Notification,ShowPopup, ShowNotification}
