import React, {useEffect, useState} from "react";
import {SegmentedControl, Select} from "@mantine/core";
import {CallUrl, CloudApi} from "../../_Layouts/RequestManager";
import {useSelector} from "react-redux";
import {Buttons, Inputs, Page} from "../index";

const ChooseResponse = ({onClose, onSave, details}) =>{
    const [isLoading,setIsLoading] = useState(false);
    const [type,setType] = useState(details?.type || "MessageList");
    const [selected,setSelected] = useState(details?.selected || "");
    const [list,setList] = useState([]);
    const {sessionId} = useSelector((state)=> state.active);

    useEffect(() => {
        setList([]);
        if(type !== "Manual") {
            // setSelected("");
            getDetails();
        }
    }, [type]);

    const getDetails = async () =>{
        setIsLoading(true);
        await CallUrl({
            type: "GET",
            url: `${CloudApi.responseOptions}/${type}/${sessionId}`,
            success: (data) =>{
                setList(data?.list || []);
            }
        })
        setIsLoading(false);
    }
    const handleSave = () =>{
        onSave(selected, {type, selected,label: type=== "Manual" ? selected : list?.find(a => a?.value === selected)?.label || ""});
        onClose()
    }

    return <Page.ModalSetup onClose={onClose} size={"30%"} title={`Choose Response`}>
        <Page.Loading isLoading={isLoading}>
            <SegmentedControl size={"xs"} data={["MessageList","Sequence","Manual"]} value={type} onChange={setType} />
            {type === "Manual" ? <Inputs.Text label={"Enter Custom Value"} value={selected} onChange={setSelected}  />:
            <Select my={10} size={"xs"} data={list}  value={selected} onChange={setSelected} />}
            <Buttons.Submit label={"Save Response"} onClick={handleSave}  />
        </Page.Loading>
    </Page.ModalSetup>
}

export default ChooseResponse;