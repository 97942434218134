import {Notification} from "../_Components";

export const ActionUserLogin = (user) => (dispatch) => dispatch({ type: "UserLogin",user});
export const ActionUserLogout = () => (dispatch) =>{
    dispatch({type: "UserLogout"});
    dispatch({type: "hidePopup"});
    Notification.Show({message: "Logged out Successfully"});
}
export const ActionUserInfo = (data) => (dispatch) => dispatch({type: "UserInfo",data})
export const UpdateActiveSession = (data)  => (dispatch) => dispatch({type: "UpdateActiveSession",data})
export const UpdatePrefUsed = (data)  => (dispatch) => dispatch({type: "UpdatePrefUsed",data})
export const DeleteActiveSession = (data)  => (dispatch) => dispatch({type: "DeleteActiveSession"})
