import React, {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import {getRandomString, isArrayUnique} from "../../_Constants/Functions";
import {Grid, Group, Select, Stack} from "@mantine/core";
import DocumentPickView from "./DocumentPickView";
import MessagePreview from "../MessagePreview";
import {Buttons, Page, TextView, Inputs} from "../index";

const ButtonsType =  forwardRef((props, ref)=>{
    const {details,msgType, type} = props;

    const [headerType,setHeaderType] = useState(details?.headerType || "NONE");
    const [headerText,setHeaderText] = useState(details?.headerText || "");
    const [headerFile,setHeaderFile] = useState(details?.file);
    const [bodyText,setBodyText] = useState(details?.bodyText || "");
    const [footerText,setFooterText] = useState(details?.footerText || "");
    const [buttons,setButtons] = useState(details?.buttons || [{title: "Button1",  id: type === "sequence" ? getRandomString(5):"id1"}]);
    const [render,setRender] = useState(0);
    const [isError,setIsError] = useState(false);
    const [errors,setErrors] = useState([]);

    useEffect(() => {
        setHeaderType(details?.headerType || "NONE");
        setHeaderText(details?.headerText || "");
        setHeaderFile(details?.file || "");
        setBodyText(details?.bodyText || "");
        setFooterText(details?.footerText || "");
        setButtons(details?.buttons || [{title: "Button1", id: type === "sequence" ? getRandomString(5):"id1"}])
    }, [details]);

    const checkIsValid = () =>{
        let errorCount = 0;
        let errors = []
        if(bodyText?.trim() === "") errorCount++;
        if(buttons?.length === 0) {
            errorCount++;
            errors.push("Please Add Buttons")
        }
        buttons?.map(a => a?.id?.trim() === "" || a?.title?.trim() === "" ? errorCount++: "");


        const idUnique = isArrayUnique(buttons?.map(a => a?.id) || []);
        const titleUnique = isArrayUnique(buttons?.map(a => a?.title) || []);
        if(!idUnique) {
            errorCount++;
            errors.push("Ids Must be Unique")
        }
        if(!titleUnique) {
            errorCount++;
            errors.push("Title Must be Unique")
        }
        if(headerType === "TEXT" && headerText?.trim() === "") {
            errorCount++;
            errors.push("Header Text is Mandatory")
        }
        if(!["TEXT","NONE"].includes(headerType)) {
            const data = !headerFile;
            if(data){
                errorCount++;
                errors.push("Media File is Mandatory")
            }
        }
        let isValid = errorCount === 0;
        setIsError(!isValid);
        setErrors(errors);
        return isValid;
    }

    useImperativeHandle(ref, () => ( {
        isValid() {
            const isValid = checkIsValid();
            let header = {type: headerType};
            if(headerType === "NONE") {
                header = undefined;
            }else if(headerType === "TEXT") {
                header = {...header, text: headerText};
            }else{
                const isUrl = headerFile?.file?.startsWith("http");
                header = {...header, [headerType?.toLowerCase()]:headerFile?.isCode ? {code: headerFile?.file}: isUrl ? {link: headerFile?.file} : {id: headerFile?.file}}
            }
            return {isValid, message: {header,bodyText,footerText,buttons}}
        }
    }));
    const handleValues = (key,value,index) =>{
        const b = buttons?.map((a,i) => {
            if(i === index) return {...a,[key]: value};
            return a;
        });
        setButtons(b);
        setRender(render+1)
    }

    return <>
        <Grid.Col span={6}>
            <Grid.Col span={12}>
                <Page.GroupGap style={{gap: 5}}>
                    <TextView.Bold size={"md"}  text={"Header"} />
                    <Select size={"xs"} data={["NONE","TEXT","IMAGE","VIDEO","DOCUMENT"]} value={headerType}  onChange={setHeaderType}/>
                </Page.GroupGap>
                {headerType === "TEXT" && <Inputs.Text label={"Header Message"} value={headerText} onChange={setHeaderText} limit={60}  error={isError && headerText?.trim() === "" ? "Message is Required For Text Type": ""} />}
            </Grid.Col>
            {!["NONE","TEXT"]?.includes(headerType) && <DocumentPickView msgType={headerType} file={headerFile} setFile={setHeaderFile} />}

            <Grid.Col span={12}>
                <TextView.TextAreaEditor label={"Body Message"} required text={bodyText} setText={setBodyText} limit={1024} minRows={4} error={isError && bodyText?.trim() === "" ? "Body text is Required": ""} />
            </Grid.Col>
            <Grid.Col span={12}>
                <Inputs.Text label={"Footer Message"} value={footerText} onChange={setFooterText} limit={60} />
            </Grid.Col>
            <Grid.Col span={12}>
                <Page.GroupApart>
                    <TextView.Bold text={"Buttons"}  variant={"gradient"} size={"md"} />
                    <Buttons.Header size={"xs"} icon={"IconPlus"} label={"Add Button"}  onClick={() => buttons?.length < 3 ? setButtons([...buttons,{ id: type === "sequence" ? getRandomString(5):"",title: ""}]): {}} />
                </Page.GroupApart>
            </Grid.Col>
            <Grid.Col span={12}>
                <Stack sx={{gap: 3}}>
                    {errors?.map((a,i) => <TextView.Error text={`${i+1}. ${a}`} align="left" size="xs" />)}
                </Stack>
            </Grid.Col>
            {buttons?.map((a,i) =>{
                return <Grid.Col span={12}>
                    <Group>
                        <Inputs.Text label={"Button Title"} value={a?.title} onChange={(value) => handleValues("title",value,i)} limit={20} />
                        {type !== "sequence" &&<Inputs.Text label={"Button Id"} value={a?.id}
                                      onChange={(value) => handleValues("id", value, i)} limit={256}/>}
                        {buttons?.length > 1 && <Page.Icon icon={"IconTrash"} color={"red"} onClick={() => setButtons(buttons?.filter((a,index) => i !== index))} />}
                    </Group>
                </Grid.Col>
            })}
        </Grid.Col>
        <Grid.Col span={6}>
            <MessagePreview type={"session"} message={{headerType,headerText,buttons,bodyText,footerText, headerFile, type: msgType}} />
        </Grid.Col>
    </>
});
export default ButtonsType;