import React, {useEffect, useRef, useState} from "react";
import {Group, Stack, Text, Textarea} from "@mantine/core";
import {Colors} from "../_Constants";
import {Icon, IconText} from "./Page";
import {Page, TextView} from "./index";
import {AddVariables} from "./MessageComponents";

export const Bold = ({text, ...props}) => <Text size={"xs"} c={"black"} fw={"700"} {...props}>{text}</Text>
export const Error = ({text, ...props}) => <Text size={"xs"} c={"red"} {...props}>{text}</Text>
export const Small = ({text, ...props}) => <Text size={"xs"} fs={12} c={"dimmed"} {...props}>{text}</Text>
export const Paragraph = ({text, ...props}) => <Stack style={{gap:2}}>
    {text?.split("\n")?.map(a =>  <Text size={"xs"} {...props}>{a}</Text>)}
</Stack>
export const Header = ({text, ...props}) => <Text size={"md"} c={Colors.Primary} fw={"600"} {...props}>{text}</Text>
export const WhatsappMessage = ({text,hideReadmore,lineClamp = 5,hideVar, ...props}) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [isOverflowing, setIsOverflowing] = useState(false);
    const textRef = useRef(null);
    useEffect(() => {
        if (textRef.current) {
            const lineHeight = parseFloat(getComputedStyle(textRef.current).lineHeight);
            const maxHeight = lineHeight * lineClamp; // 5 lines max
            setIsOverflowing(textRef.current.scrollHeight > maxHeight);
        }
    }, [text]);
    const formatText = (text) =>{
        let formatted =   text?.replaceAll(/(?<=^|\s)\*(\S[^*]*\S|\S)\*(?=\s|$)/g, '<strong>$1</strong>')
            ?.replaceAll(/(?<=^|\s)\_(\S[^*]*\S|\S)\_(?=\s|$)/g, '<i>$1</i>')
            ?.replaceAll(/(?<=^|\s)\~(\S[^*]*\S|\S)\~(?=\s|$)/g, '<strike>$1</strike>');
        if(!hideVar) {
            formatted = formatted?.replaceAll(/\{(.*?)\}/g, '<Mark>$1</Mark>');
        }
        return formatted
    }

    return <Stack style={{gap:3}}>
        <Text ref={textRef} size={"sm"} {...props} style={{
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: (isExpanded || hideReadmore) ? "unset" : lineClamp,
            overflow: "hidden",
        }}>
            {formatText(text)?.split("\n")?.map(a => <Text size={"sm"} {...props}>
                <span dangerouslySetInnerHTML={{__html: a}}/>
            </Text>)}

        </Text>
        {isOverflowing && !hideReadmore && <Group onClick={() => setIsExpanded(!isExpanded)} justify={"flex-end"} style={{cursor: "pointer"}}>
            <Text style={{textDecoration: "unset", color: "blue",align: "right"}} >{isExpanded ? "Read Less" : "Read More"}</Text>
        </Group>}
    </Stack>

}
export const TextLines = ({text, maxLine = 10, ...props}) => {
    return <Text lineClamp={maxLine} {...props}>
        {text?.toString()?.split("\n")?.map(a =>  <Text size={"xs"} {...props}>{a}</Text>)}
    </Text>

}
export const TextAreaEditor = ({text, setText,limit,label, required,hideVar, ...props}) => {
    const textAreaRef = useRef(null);
    const [isVar,setIsVar] = useState(false)
    const insertTextAtCursor = (startText, endText) => {
        const textarea = textAreaRef.current;
        if (!textarea) return;
        const start = textarea.selectionStart;
        const end = textarea.selectionEnd;
        const newText = text.substring(0, start) + startText +  text.substring(start, end) +endText+ text.substring(end);
        setText(newText);

        setTimeout(() => {
            textarea.selectionStart = textarea.selectionEnd = start + startText.length;
            textarea.focus();
        }, 0);
    };
    const addVar = (startText) =>{
        const textarea = textAreaRef.current;
        if (!textarea) return;
        const start = textarea.selectionStart;
        const newText = text.substring(0, start) + startText + text.substring(start);
        setText(newText);

        setTimeout(() => {
            // textarea.selectionStart = textarea.selectionEnd = start + startText.length;
            textarea.focus();
        }, 0);
    }

    return <Stack style={{gap: 3}}>
        {isVar && <AddVariables onClose={() => setIsVar(false)} onSave={(value) => addVar(value)} />}
        <Page.GroupApart>
            <Page.GroupGap>
                <Text size={"xs"} fw={"600"}>{label}</Text>
                {required && <TextView.Bold text={"*"} c={"red"} />}
            </Page.GroupGap>
            <Group>
                <Page.GroupGap>
                    <Icon variant={"outline"} icon={"IconBold"} onClick={() => insertTextAtCursor("*","*")} />
                    <Icon variant={"outline"} icon={"IconItalic"} onClick={() => insertTextAtCursor("_","_")} />
                    <Icon variant={"outline"} icon={"IconStrikethrough"} onClick={() => insertTextAtCursor("~","~")} />
                    {!hideVar && <Page.Card p={1}>
                        <IconText onClick={() => setIsVar(true)} icon={"IconPlus"} text={`Add Variable`} color={"blue"}  />
                    </Page.Card>}
                </Page.GroupGap>
                {limit && <Text size={"xs"} c={"dimmed"}>{text?.length || 0} / {limit}</Text>}
            </Group>
        </Page.GroupApart>
        <Textarea
            variant={"filled"}
            ref={textAreaRef}
            value={text}
            onChange={(e) => setText(e.target.value)}
            placeholder="Type your message here..."
            maxRows={7}
            minRows={4}
            autosize
            {...props}
        />
    </Stack>
}
