import { useState, useEffect } from "react";
import {Group, Text} from "@mantine/core";

const InternetStatus = () => {
    const [isOnline, setIsOnline] = useState(navigator.onLine);
    const [show,setShow] = useState(false);
    const [render,setRender] = useState(0);

    useEffect(() => {
        const handleOnline = () => setIsOnline(true);
        const handleOffline = () => setIsOnline(false);
        window.addEventListener("online", handleOnline);
        window.addEventListener("offline", handleOffline);
        return () => {
            window.removeEventListener("online", handleOnline);
            window.removeEventListener("offline", handleOffline);
        };
    }, []);

    useEffect(() => {
        if(isOnline && !show) {
            setShow(true);
            setTimeout(() => setShow(false), 1000)
        }
        setRender(render + 1)
    },[isOnline]);

    return <>
            {(!isOnline || (show && render > 1)) && <Group style={{width: "100%"}} bg={isOnline ? "green" : "red"} justify={"center"}>
                <Text c={"white"}>{isOnline ? "Back to Online" : "No Internet Connection"}</Text>
            </Group>}
        </>
};

export default InternetStatus;