import {useSelector} from "react-redux";
import {Colors} from "../_Constants";
import {Group, Stack, Text, Tooltip} from "@mantine/core";
import React, {useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {Page} from "../_Components";

const iconParams = {size: 30, color: Colors.Primary}

const SidebarList = [
    {icon: "IconGauge",label: 'Dashboard',path: ``},
    {icon: "IconUsers",label: 'CRM',path: `/crm`},
    {icon: "IconSettingsAutomation",label: 'Flows',path: `/flows`},
    {icon: "IconPhoto",label: 'Attachments',path: `/attachments`},
    {icon: "IconTemplate",label: 'Templates',path: `/templates`},
    {icon: "IconForms",label: 'Forms',path: `/forms`},
    {icon: "IconGraph",label: 'Sequence',path: `/sequence`},
    {icon: "IconList",label: 'MessageList',path: `/message-list`},
    {icon: "IconInbox",label: 'Inbox',path: `/inbox`},
    {icon: "IconMessage",label: 'Chatbot',path:`/chatbots`},
    {icon: "IconMessage",label: 'Programs',path: `/programs`},
    {icon: "IconList",label: 'Scheduled',path: `/schedules`},
    {icon: "IconTimeline",label: 'Message Logs',path:`/logs`},
    {icon: "IconSettings",label: 'Settings',path:`/settings`},
];
const AdminSidebar = [
    {icon: "IconGauge",label: 'Dashboard',path: `/`},
    {icon: "IconUsers",label: 'Users',path: `/users`},
    {icon: "IconUser",label: 'Sessions',path: `/sessions`},
    {icon: "IconBell",label: 'Notifications',path: `/notifications`},
    {icon: "IconCurrencyRupee",label: 'Payments',path: `/payments`},
    {icon: "IconLink",label: 'PayLinks',path: `/paylinks`},
    {icon: "IconListCheck",label: 'Plans',path: `/update-plan`},

];
const R365Sidebar = [
    {icon: "IconGauge",label: 'Dashboard',path: `/r365`},
    {icon: "IconBook",label: 'Books',path: `/r365/books`},
    {icon: "IconUsers",label: 'Customers',path: `/r365/customers`},
    {icon: "IconMessage",label: 'Today Messages',path: `/r365/messages`},
    {icon: "IconSettings",label: 'Actions',path: `/r365/actions`},
    {icon: "IconHomeStats",label: 'Stats',path: `/r365/Stats`},
];
const SidebarItems = {
    "User": SidebarList,
    "Admin": AdminSidebar,
    "R365": R365Sidebar,
}

const SideBar = ({opened}) =>{
    const {email} = useSelector(state => state.user);
    const {alias,r365,addOns} = useSelector(state => state.active);
    const isAdmin = email === "admin@magaduka.in";
    let lists = SidebarItems?.[isAdmin ? "Admin" :"User"]
    const navigation = useNavigate();
    const location = useLocation();


    const handleNavigation = (path) => navigation(isAdmin ? path :`device/${alias}${path}`);

    return <Stack py={5}  bg={Colors.grey}>
        {lists.map(a => <SidebarComponent
            key={a?.label?.toString()}
            data={a}
            selected={location?.pathname?.replace(`/device/${alias}`,"") === a?.path}
            opened={opened}
            onClick={handleNavigation}
        />)}
    </Stack>
}
export default SideBar;

const SidebarComponent = ({data, selected, opened,onClick}) =>{
    return  <Stack style={{gap: 1,cursor: "pointer"}} align={opened ? "none": "center"} onClick={() => onClick(data?.path)}>
        {opened ? <Group mx={5} position={"center"} style={{borderRadius: 10}} color={"white"} bg={selected ? Colors.Primary: "transparent"} p={5}>
                <Page.Icon icon={data?.icon} {...iconParams} color={selected? "white": Colors.Primary} />
                {opened && <Text c={selected? "white": Colors.Primary}>{data?.label}</Text>}
            </Group>:
            <Tooltip.Floating label={data?.label}>
                <Group position={"center"} style={{borderRadius: 10}} color={Colors.Primary} bg={selected ? Colors.Primary: "transparent"} p={5}>
                    <Page.Icon icon={data?.icon} {...iconParams}  iconProps={{size: 70}} color={selected? "white": Colors.Primary} />
                </Group>
            </Tooltip.Floating>}
    </Stack>
}