import React, {useEffect, useState} from "react";
import {Divider, Grid, Group, Image, Loader, Paper, Stack} from "@mantine/core";
import {useSelector} from "react-redux";
import {TextView, Page, Buttons} from "./index"
import {CallUrl,CloudApi} from "../_Layouts/RequestManager";
import FileViewOptions from "./MessageComponents/FileViewOptions";

const MessagePreview = ({message,data,variables, type = "message", hideReadmore = false}) =>{

    const renderSessionMessage = () =>{
        switch (message?.type) {
            case "Text":
                return <TextView.WhatsappMessage hideReadmore={true} text={message?.message} />
            case "Image":
                return <>
                    {message?.file?.file && <FileViewOptions file={message?.file?.file} type={message?.type?.toLowerCase()} />}
                    <TextView.WhatsappMessage hideReadmore={true} text={message?.message} />
                </>
            case "Audio":
            case "Video":
            case "Document":
                return <>
                    {message?.file?.file && <FileViewOptions file={message?.file?.file} type={message?.type?.toLowerCase()} />}
                    <TextView.WhatsappMessage hideReadmore={true} text={message?.message} />
                </>
            case "Buttons":
                return  <>
                    {!["TEXT","NONE"].includes(message?.headerType) && message?.headerFile?.file && <FileViewOptions file={message?.headerFile?.file} type={message?.headerType} />}
                    {["TEXT"].includes(message?.headerType) && <TextView.Bold text={message?.headerText} />}
                    <TextView.WhatsappMessage hideReadmore={true} text={message?.bodyText} />
                    <TextView.Small color={"dimmed"} text={message?.footerText} />
                    <Group grow my={5}>
                        {message?.buttons?.map(a => <Buttons.Header  label={a?.title} payload={a?.id} />)}
                    </Group>

                </>
            case "Url":
            case "List":
                return  <>
                    {!["TEXT","NONE"].includes(message?.headerType) && message?.headerFile?.file && <FileViewOptions file={message?.headerFile?.file} type={message?.headerType} />}
                    {["TEXT"].includes(message?.headerType) && <TextView.Bold text={message?.headerText} />}
                    <TextView.WhatsappMessage hideReadmore={true} text={message?.body} />
                    <TextView.Small color={"dimmed"} text={message?.footer} />
                    {message?.btnText && <Buttons.Header  fullWidth label={message?.btnText} />}
                </>
            case "Form":
                return  <>
                    {!["TEXT","NONE"].includes(message?.headerType) && message?.headerFile?.file && <FileViewOptions file={message?.headerFile?.file} type={message?.headerType} />}
                    {["TEXT"].includes(message?.headerType) && <TextView.Bold text={message?.headerText} />}
                    <TextView.WhatsappMessage hideReadmore={true} text={message?.body} />
                    <TextView.Small color={"dimmed"} text={message?.footer} />
                    {message?.btnText && <Buttons.Header  fullWidth label={message?.btnText} />}
                </>
            default:
                return <TextView.WhatsappMessage hideReadmore={true} text={type} />
        }
    }
    const renderPreview = () => {
        switch (true){
            case !!message?.template:
                return <TemplateView name={message?.template?.name} lang={message?.template?.language?.code} components={message?.template?.components} />

            case !!message?.draftForm:
                return  <>
                    {!["TEXT","NONE"].includes(message?.header?.type)&& <FileViewOptions file={message?.header?.[message?.header?.type?.toLowerCase()]} type={message?.header?.type?.toLowerCase()} />}
                    {["TEXT"].includes(message?.header?.type) && <TextView.Bold text={message?.header?.text} />}
                    <TextView.WhatsappMessage text={"This is a test message to try your flow."} />
                    <TextView.WhatsappMessage text={"Team Whatsapp"} color={"dimmed"} />
                    <Buttons.Header  fullWidth label={"Open Form"} />
                </>
            case !!message?.form:
                return <>
                    {!["TEXT","NONE"].includes(message?.header?.type) && <FileViewOptions file={message?.header?.[message?.header?.type?.toLowerCase()]} type={message?.header?.type?.toLowerCase()} />}
                    {["TEXT"].includes(message?.header?.type) && <TextView.Bold text={message?.header?.text} />}
                    <TextView.WhatsappMessage text={message.body} />
                    <TextView.WhatsappMessage text={message.footer} color={"dimmed"} />
                    <Buttons.Header  fullWidth label={message?.btnText} />
                </>
            case !!message?.buttons:
                return <>
                    {!["TEXT","NONE"].includes(message?.header?.type) && <FileViewOptions file={message?.header?.[message?.header?.type?.toLowerCase()]} type={message?.header?.type?.toLowerCase()} />}
                    {["TEXT"].includes(message?.header?.type) && <TextView.Bold text={message?.header?.text} />}
                    <TextView.WhatsappMessage text={message.bodyText} />
                    <TextView.WhatsappMessage text={message.footerText} color={"dimmed"} />
                    <Group grow p={5}>
                        {message?.buttons?.map(a => <Buttons.Header label={a?.title} />)}
                    </Group>
                </>
            case !!message?.list:
                return  <>
                    {!["TEXT","NONE"].includes(message?.header?.type) && <FileViewOptions file={message?.header?.[message?.header?.type?.toLowerCase()]} type={message?.header?.type?.toLowerCase()} />}
                    {["TEXT"].includes(message?.header?.type) && <TextView.Bold text={message?.header?.text} />}
                    <TextView.WhatsappMessage text={message.body} />
                    <TextView.WhatsappMessage text={message.footer} color={"dimmed"} />
                    <Buttons.Header  fullWidth label={message?.btnText} />
                </>
            case !!message?.urlButton:
                return  <>
                    {!["TEXT","NONE"].includes(message?.header?.type) && <FileViewOptions file={message?.header?.[message?.header?.type?.toLowerCase()]} type={message?.header?.type?.toLowerCase()} />}
                    {["TEXT"].includes(message?.header?.type) && <TextView.Bold text={message?.header?.text} />}
                    <TextView.WhatsappMessage text={message.body} />
                    <TextView.WhatsappMessage text={message.footer} color={"dimmed"} />
                    <Buttons.Header  label={message?.urlButton?.text} />
                </>
            case !!message?.audio:
                return <>
                    <FileViewOptions file={message?.audio} type={"Audio"} />
                    <TextView.WhatsappMessage text={message.message} />
                </>
            case !!message?.video:
                return <>
                    <FileViewOptions file={message?.video} type={"Video"} />
                    <TextView.WhatsappMessage text={message.message} />
                </>

            case !!message?.document:
                return <>
                    <FileViewOptions file={message?.document} type={"Document"} />
                    <TextView.WhatsappMessage text={message.message} />
                </>
            case !!message?.image:
                return <>
                    <FileViewOptions file={message?.image} type={"image"}  />
                    <TextView.WhatsappMessage text={message.message} />
                </>
            case !!message?.message:
            default:
                return <TextView.WhatsappMessage text={message.message} />

        }
    }
    const renderInboxMessage = () => {
        const {type,text,image} = message;
        switch (message?.type){
            case 'text':
                return <TextView.WhatsappMessage size={"sm"} text={text?.body?.toString()}/>
            case 'image':
                return <>
                    <FileViewOptions file={image} type={"image"} size={50} />
                    <TextView.WhatsappMessage text={image?.caption?.toString()}/>
                </>
            case 'audio':
            case 'video':
            case 'document':
                return  <FileViewOptions file={message?.[type]} type={message?.type} />
            case 'button':
                return  <>
                <Page.GroupGap mb={5}>
                    <TextView.Bold text={"Button Response: "} />
                    <TextView.Small text={message?.button?.text || ""} />
                </Page.GroupGap>
                    <Page.GroupGap mb={5}>
                        <TextView.Bold text={"Payload: "} />
                        <TextView.Small text={message?.button?.payload || ""} />
                    </Page.GroupGap>
                    </>
            case "template":
                return <TemplateView name={message?.template?.name} lang={message?.template?.language?.code} components={message?.template?.components} />
            case "interactive":
                return  <>
                    {renderInteractive()}
                </>
            default:
                break;
        }
    }
    const renderInteractive = () => {
        const {header, body, action, footer, type, button_reply, list_reply} = message?.interactive;
        return <Stack style={{gap: 3}}>
            {header?.text && <TextView.Bold text={header?.text}/>}

            {header?.image?.link && <Group justify={"center"} w={"100%"}>
                <Image src={header?.image?.link}  h={100} fit={"contain"}/>
            </Group>}

            {body?.text && <TextView.WhatsappMessage size={"sm"} text={body?.text} hideReadmore={hideReadmore} />}
            {footer?.text && <TextView.Small color={"dimmed"} text={footer?.text}/>}
            {type === "button" && action?.buttons?.length > 0 && <Group my={10} grow>
                {action?.buttons?.map(a => {
                    return <Buttons.Header label={a?.reply?.title} payload={a?.reply?.id} />
                })}
            </Group>}
            {type === "list" && <Buttons.Header m={5} label={action?.button} icon={"IconList"} />}
            {type === "flow" && <Buttons.Header m={5} label={action?.parameters?.flow_cta} />}
            {action?.name === "cta_url" && action?.parameters && <Buttons.Header m={5} icon={"IconLogout"} label={action?.parameters?.display_text} />}

            {type === "button_reply" && <Group justify={"flex-start"}>
                <TextView.Bold text={`${type?.toUpperCase()}: `}/>
                <TextView.Small text={button_reply?.title}/>
            </Group>}
            {type === "list_reply" && <Group justify={"flex-start"}>
                <TextView.Bold text={`${type?.toUpperCase()}: `}/>
                <Stack style={{gap: 3}}>
                    <TextView.Small text={list_reply?.title}/>
                    <TextView.Small text={list_reply?.description}/>
                </Stack>
            </Group>}
            {type === "nfm_reply" && <TextView.Bold mb={5} text={"Flow Response Received"} />}
        </Stack>
    }

    return <Stack style={{gap:2}} px={5}>
        {message && <>
            {type ==="message" && renderPreview()}
            {type ==="session" && <Paper shadow={"md"} withBorder p={5} style={{overflow: "hidden"}}>
                <TextView.Bold text={`Preview`} variant={"gradient"} />
                {renderSessionMessage()}
            </Paper>}
            {type ==="Inbox" && renderInboxMessage()}
            {type ==="Template" && <PreviewTemplate message={message} variables={variables} />}
        </>}
    </Stack>
}
export default MessagePreview;

const TemplateView = ({name,lang,components}) =>{
    const {sessionId} = useSelector(state => state.active)
    const [isOpen,setOpen]=useState(false);
    const [isLoading,setIsLoading] = useState(false);
    const [details,setDetails] = useState();
    const [variables,setVariables] = useState();

    const generateVarFromComponents = () =>{
        let vari = [];
         components?.map(a =>{
            switch(a?.type){
                case "header":
                   a?.parameters?.map(b => {
                       vari.push({key: b?.type?.toLowerCase(), value: b?.[b?.type] })
                   });
                    break;
                case "body":
                    a?.parameters?.map((b,i) => {
                        vari.push({key: `b_${i+1}`, value: b?.[b?.type]})
                    });
                    break;
                case "button":
                    break;
            }
        });
         setVariables(vari);
    }

    useEffect(() => {
        if(isOpen) {
            getTemplatesDetails();
            generateVarFromComponents()
        }
    },[isOpen]);
    const getTemplatesDetails = async () => {
        setIsLoading(true);
        await CallUrl({
            type: "GET",
            url: `${CloudApi.templateNameDetails}/${sessionId}/${name}/${lang}`,
            success: (data) =>{
                console.log("Data", data)
                setDetails(data?.details);
                setIsLoading(false);
            },
            error: () =>{
                setIsLoading(false);
            }

        })
    }
    return <>
        <Page.GroupApart>
            <Page.GroupGap>
                <TextView.Bold text={"Template:"} size={"md"} />
                <TextView.Small text={name} size={"md"} />
            </Page.GroupGap>
            <Page.Icon icon={isOpen ? "IconEyeOff":"IconEye"} onClick={() => setOpen(!isOpen)} />
        </Page.GroupApart>
        {isOpen && <>
            <Divider />
            {isLoading ? <Loader />: details && <PreviewTemplate message={details?.message} variables={variables} showVar={false} />}
        </>}
    </>
}

const PreviewTemplate = ({message,variables, showVar = true}) =>{
    const renderTextWithVar = ({text,variables}) =>{
        let final = text;
        variables?.map(a => {
            const v = a?.key?.split("_")?.[1];
            final = final?.replaceAll(`{{${v}}}`,a?.value);
        })
        return <TextView.WhatsappMessage text={final} hideReadmore={true} />
    }
    return <Grid>
        <Grid.Col span={12}>
            {message?.header && message?.header?.type !== "text" && <FileViewOptions type={message?.header?.type} file={variables?.find(a => a?.key === message?.header?.type?.toLowerCase())?.value || message?.header?.[message?.header?.type]} />}
            {message?.header?.text && renderTextWithVar({text: message?.header?.text, variables: variables?.filter(a => a?.key?.includes("h_"))})}
            {message?.body?.text && renderTextWithVar({text: message?.body?.text, variables: variables?.filter(a => a?.key?.includes("b_"))})}
            {message?.footer?.text && renderTextWithVar({text: message?.footer?.text,variables: []})}
            {message?.buttons?.length > 0 && <Group grow my={5}>
                {message?.buttons?.map(a => <Buttons.Header label={a?.text || a?.title}/>)}
            </Group>}
        </Grid.Col>
    </Grid>
}