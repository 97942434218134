import React, {useEffect, useState, forwardRef, useImperativeHandle,} from 'react';
import {
    Select,
    Grid,
    Text,
    Stack, Paper,
} from "@mantine/core";
import {useSelector} from "react-redux";
import {CallUrl, CloudApi} from "../../_Layouts/RequestManager";
import ChooseResponse from "./ChooseResponse";
import AddVariables from "./AddVariables";
import DocumentPicker from "./DocumentPicker";
import MessagePreview from "../MessagePreview";
import {Inputs, Page, TextView} from "../index";

const WBAMessageCreate = forwardRef((props, ref) => {
    const {sessionId,details} = props;
    const {templates} = useSelector(state => state.active);
    const [templateId, setTemplateId] = useState(details?.message?.templateId);
    const [tempDetailsLoading, setTempDetailsLoading] = useState(false);
    const [templateDetails, setTemplateDetails] = useState({});
    const [variables, setTemplateVariables] = useState([]);
    const [isError, setIsError] = useState(false);
    const [render, setRender] = useState(0);
    const handleVariables = (i,key,value, details = {})=>{
        const v = variables;
        v[i]={...v[i],value,details};
        setTemplateVariables(v);
        setRender(render+1);
    }
    useEffect(() =>{
        if(details) {
            setTemplateId(details?.editOptions?.message?.templateId);
            setTemplateVariables(details?.editOptions?.message?.variables);
        }},[details])
    useEffect(() => {
        if (templateId) getTemplatesDetails();
    }, [templateId]);

    const getTemplatesDetails = async () => {
        setTempDetailsLoading(true);
        await CallUrl({
            type: "GET",
            url: `${CloudApi.templateDetails}/${sessionId}/${templateId}`,
            success: (temp) =>{
                setTemplateDetails(temp?.details);
                setTemplateVariables(details && details?.editOptions?.message?.templateId === temp?.details?.id ? details?.editOptions?.message?.variables : temp?.details?.variables)

            }
        })
      setTempDetailsLoading(false);
    }
    useImperativeHandle(ref, () => ( {
        isValid() {
            const message = {
                templateId,
                name: templateDetails?.name,
                language: templateDetails?.language ||"en_US",
                variables,
                buttons: templateDetails?.message?.buttons
            };
            const isVarValid = variables?.filter(a => a?.key?.includes("b_") && a?.value?.trim() === "")?.length === 0;
            const isValid = !!templateId && isVarValid;
            setIsError(!isValid);
            return {message,isValid};
        },
        reset(){
            setTemplateId("");
            setTemplateVariables([]);
            setTemplateDetails({})
        }
    }));

    return <>
        {/*<Page.GroupApart>*/}
            {sessionId && <Grid.Col span={6}>
                    <Select required size={"xs"} searchable label={"Choose Template"} placeholder={"Choose Template"}
                            data={templates || []}
                            value={templateId}
                            onChange={setTemplateId}
                            error={isError && !templateId ? "Template is Required": ""}
                    />
                </Grid.Col>}
            <Grid.Col span={6}></Grid.Col>
            <Grid.Col span={6} style={{width: "50%",alignItems: "baseline"}}>
                {variables && !tempDetailsLoading && <Grid.Col span={12}>
                    {variables?.map((a, i) => {
                        return <>
                            {["image","document","video"].includes(a?.key) && <HeaderVariable msgType={a?.key} index={i} data={a} onChange={handleVariables} />}
                            {a?.key?.includes("buttons_") && <ButtonVariable index={i} data={a} onChange={handleVariables} />}
                            {a?.key?.includes("b_") && <BodyVariable index={i} data={a} onChange={handleVariables} isError={isError} />}
                        </>
                    })}
                </Grid.Col>}
            </Grid.Col>
            <Grid.Col span={6} style={{width: "50%",alignItems:"baseline"}}>
                {sessionId && templateId && <Grid.Col span={12}>
                    <Page.LoadingIcon isLoading={tempDetailsLoading}>
                        <Page.Card>
                            <Stack style={{gap: 3}} p={5}>
                                <TextView.Bold variant={"gradient"} text={"Message Preview"} />
                                <MessagePreview type={"Template"} message={templateDetails?.message} variables={variables} showVar={false} />
                            </Stack>
                        </Page.Card>
                    </Page.LoadingIcon>
                </Grid.Col>}
            </Grid.Col>
        {/*</Page.GroupApart>*/}
        {isError && <Text color={"red"}> Please Fill All Mandatory Fields</Text>}
    </>
});
export default WBAMessageCreate;

const ButtonVariable = ({index, data, onChange}) =>{
    const [isChoose,setIsChoose] = useState(false);
    return <Stack style={{gap: 1}} my={3}>
        <Page.Card>
            {isChoose && <ChooseResponse details={data?.details} onClose={() => setIsChoose(false)} onSave={(value, details) => onChange(index,data?.key,value,details)} />}
            <Page.GroupApart my={3}>
                <TextView.Bold text={data?.label} variant={"gradient"}  />
                <TextView.Bold c={"blue"} text={"Add Response"} style={{cursor: "pointer", textDecoration: "underline"}} onClick={() => setIsChoose(true)}  />
            </Page.GroupApart>
            {data?.details && <TextView.Bold text={`${data?.details?.type} - ${data?.details?.label}`}/>}
        </Page.Card>
    </Stack>
}
const BodyVariable = ({index, data, onChange,isError}) =>{
    const [isVar,setIsVar] = useState(false);
    return <Stack style={{gap: 1}}>
        <Paper shadow={"md"} withBorder p={5}>
            {isVar && <AddVariables onClose={() => setIsVar(undefined)} onSave={(value) => onChange(index,data?.key,value)} />}
            <Page.GroupApart my={3}>
                <TextView.Bold text={`Variable {{${data?.label}}}`}  />
                <TextView.Bold c={"blue"} text={"Add Variable"} style={{cursor: "pointer", textDecoration: "underline"}} onClick={() => setIsVar(true)}  />
            </Page.GroupApart>
            <Inputs.Text
                value={data?.value}
                placeholder="Enter your text"
                onChange={(value) => onChange(index,data?.key, value)}
                description={`Sample : ${data?.sample}`}
                error={isError && data?.value?.trim() === "" ? "Field is Required": ""}
            />
        </Paper>
    </Stack>
}
const HeaderVariable = ({msgType,index, data, onChange}) =>{
    const [opened,setOpened] = useState(false);

    const handleSave = ({folder,file,isCode,label}) => {
        onChange(index, data?.key, file, {file,folder, isCode,label});
        setOpened(false)
    }

    return <Stack style={{gap: 1}}>
            <Paper shadow={"md"} withBorder p={5}>
                <Page.GroupApart my={3}>
                    <TextView.Bold text={data?.key}  />
                    <Page.GroupGap>
                        <TextView.Bold text={data?.details?.label}  />
                        <TextView.Bold c={"blue"} text={`Change ${data?.key}`} style={{cursor: "pointer", textDecoration: "underline"}} onClick={() => setOpened(true)}  />
                    </Page.GroupGap>
                </Page.GroupApart>
            </Paper>
        {opened && <DocumentPicker msgType={msgType} onChange={handleSave} onClose={() => setOpened(false)} details={data?.details}  />}
    </Stack>
}
