import {Grid,SegmentedControl, Select, TextInput} from "@mantine/core";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {Buttons, Page} from "../index";


const fileFilter = {
    "image": ["image"],
    "audio": ["audio"],
    "video": ["video"],
    "document": ["image","audio","video","application"]
}
const DocumentPicker = ({msgType,onClose,details,onChange}) =>{
    const {files} = useSelector(state => state.active);
    useEffect(() => {
        if(details?.file){
            const folder = files?.find(a => a?.files?.filter(b => b?.media === file)?.length > 0)?.value;
            setFolder(folder);
            const f =  files?.find(a => a?.value === folder)?.files?.find(b => b?.media === file)?.value;
            setFile(f);

            if(!folder){
                setManual(file);
                setType("url")
            }
        }
    }, [details]);

    const [folder,setFolder] = useState("");
    const [file,setFile] = useState(details?.file || "");
    const [manual,setManual] = useState(details?.file || "");

    const [isError,setIsError] = useState(false);
    const [type,setType] = useState("attachments");

    const handleImageSave = ()=>{
        if(file){
            const selectedFile = files.find(a => a.value === folder)?.files?.find(a => a?.value === file);
            const isCode = selectedFile?.media?.startsWith("fi_");
            onChange({folder,file: selectedFile?.media,isCode, label: selectedFile?.label});
        }else if(manual){
            const selectedFile = files.find(a => a.value === folder)?.files?.find(a => a?.value === file || a?.media === file);
            if(selectedFile){
                const isCode = selectedFile?.media?.startsWith("fi_");
                onChange({folder,file: selectedFile?.media,isCode, label: selectedFile?.label});
            }else{
                const isCode = manual?.startsWith("fi_");
                onChange({file: manual,isCode});
            }
        }
        onClose()
    }

    return <Page.ModalSetup opened={true} size={"50%"} onClose={onClose} title={<b>Choose File</b>}>
        <Grid>
            <Grid.Col span={12}>
                <SegmentedControl data={[{label: "From Attachments",value: "attachments"}, {label: "Manual Url", value: "url"}]} value={type} onChange={setType}  />
            </Grid.Col>
            {type === "attachments" && <>
                <Grid.Col span={12} lg={3} xl={3}>
                    <Select size={"xs"} nothingFound={"No Attachments.. Please Create"} label={"Choose Folder"}
                            data={files}
                            value={folder}
                            onChange={setFolder}
                    />
                </Grid.Col>
                {folder && <Grid.Col lg={3} xl={3} span={12}>
                    <Select size={"xs"} nothingFound={"No Files.. Please Add"}
                            label={"Choose File"}
                            data={files.find(a => a.value === folder)?.files?.filter(a => fileFilter?.[msgType?.toLowerCase()]?.includes(a?.type)) || []}
                            value={file}
                            onChange={setFile}
                    />
                </Grid.Col>}
            </>}
            {type === "url" &&  <Grid.Col span={12}>
                <TextInput sx={{width: "60%"}} size={"xs"}
                           label={"Enter Attachment Code or Url"} value={manual}
                           onChange={(e) => setManual(e?.target?.value)}
                           error={isError && file?.trim() === ""? "Please Provide Link or code of Attachment": ""}  />
            </Grid.Col>}
            <Grid.Col span={12}>
                <Buttons.Submit  fullWidth  onClick={handleImageSave} label={"Choose File"} />
            </Grid.Col>
        </Grid>
    </Page.ModalSetup>
}
export default DocumentPicker;
