import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";
import {getRandomString, isArrayUnique} from "../../_Constants/Functions";
import {Button, Divider, Grid, Group, Select, Stack, Text} from "@mantine/core";
import DocumentPickView from "./DocumentPickView";
import MessagePreview from "../MessagePreview";
import {TextView, Page,Inputs, Buttons} from "../index";

const InteractiveMessage = forwardRef((props, ref)=>{
    const {msgType,details, type} = props;

    const ref1 = useRef();
    const [headerType,setHeaderType] = useState(details?.headerType || (msgType === "List"? "TEXT": "NONE"));
    const [headerText,setHeaderText] = useState(details?.headerText || "");
    const [headerFile,setHeaderFile] = useState(details?.file || details?.headerFile || "");
    const [body,setBody] = useState(details?.body || "");
    const [footer,setFooter] = useState(details?.footer || "");
    const [btnText,setBtnText] = useState(details?.btnText || "");
    const [btnUrl,setBtnUrl] = useState(details?.btnUrl || "");
    const [sections,setSections]= useState(details?.sections || [{title: "Section 1",list: [{id:type === "sequence" ? getRandomString(5) : "id1", title: "Title 1",description: ""}]}]);
    const [render,setRender] = useState(0);
    const [isError,setIsError] = useState(false);
    const [errors,setErrors] = useState([]);

    useEffect(() => {
       setHeaderType(details?.headerType || (msgType === "List"? "TEXT": "NONE"));
        setHeaderText(details?.headerText || "");
        setHeaderFile(details?.file || details?.headerFile || "");
        setBody(details?.body || "");
        setFooter(details?.footer || "");
        setBtnText(details?.btnText || "");
        setBtnUrl(details?.btnUrl || "");
        setSections(details?.sections || [{title: "Section 1",list: [{id:type === "sequence" ? getRandomString(5) : "id1", title: "Title 1",description: ""}]}]);
    }, [details]);
    const checkIsValid = () =>{
        let errorCount = 0;
        let errors = []
        if(body?.trim() === "") errorCount++;
        if(sections?.length === 0) {
            errorCount++;
            errors.push("Please Add Sections")
        }
        const ids = [];
        const titles = [];
        sections?.map(a => {
            if(a?.title?.trim() === "" || a?.list?.length === 0) errorCount++;
            ids.push(...(a?.list?.map(a => a?.id) || []))
            titles.push(...(a?.list?.map(a => a?.title) || []))
        });
        const idUnique = isArrayUnique(ids?.filter(a => a?.trim() !== ""));
        const titleUnique = isArrayUnique(titles?.filter(a => a?.trim() !== ""));

        if(!idUnique) {
            errorCount++;
            errors.push("Ids Must be Unique")
        }
        if(!titleUnique) {
            errorCount++;
            errors.push("Title Must be Unique")
        }
        if(headerType === "TEXT" && headerText?.trim() === "") {
            errorCount++;
            errors.push("Header Text is Mandatory")
        }

        if(!["TEXT","NONE"].includes(headerType)) {
            const data = ref1?.current?.isValid();
            if(!data?.isValid){
                errorCount++;
                errors.push("Media File is Mandatory")
            }
        }
        let isValid = errorCount === 0;
        setIsError(!isValid);
        setErrors(errors);
        return isValid;
    }
    useImperativeHandle(ref, () => ( {
        isValid() {
            let isValid = false;
            setErrors([]);
            const {file, isCode} = ref1.current?.isValid() || {};

            let message = {headerText,body,footer,file};
            let msgDetails = {headerType,headerText,body,footer, btnText,btnUrl,sections,file};
            if(msgType === "Url"){
                let header1 = {type: headerType};
                if(headerType === "NONE") {
                    header1 = undefined;
                }else if(headerType === "TEXT") {
                    header1 = {...header1, text: headerText};
                }else{
                    const isUrl = headerFile?.file?.startsWith("http");
                    header1 = {...header1, [headerType?.toLowerCase()]:headerFile?.isCode ? {code: headerFile?.file}: isUrl ? {link: headerFile?.file} : {id: headerFile?.file}}
                }
                isValid =  body?.trim() !== "" && btnText?.trim() !== "" && btnUrl?.trim() !== "";
                message = {...message,header: header1,urlButton: {text: btnText,link: btnUrl}}
            }else{
                isValid =  checkIsValid();
                message = {...message,headerText,btnText,list: sections}
            }

            setIsError(!isValid);
            return {message,msgDetails, isValid};
        }
    }));

    const handleSections =()=>{
        setSections([...sections, {title: "",list: [{id: type === "sequence" ? getRandomString(5) : "",title: ""}]}]);
        setRender(render+1);
    }
    const handleListItems =(index)=>{
        let count = 0;
        sections?.map(a => count += (a?.list?.length|| 0));
        if(count < 10){
            const s = sections?.map((a,i) => i === index ? {...a,list: [...(a?.list||[]),{id: type === "sequence" ? getRandomString(5) : "",title: "",description:""}]}: a)
            setSections(s);
            setRender(render+1);
        }
    }
    const handleRemoveList = (sectionId,listId) =>{
        const s = sections?.map((a,i) => i === sectionId ? {...a,list: a?.list?.filter((b,j) => j !== listId)}: a)
        setSections(s);
        setRender(render+1);
    }
    const DeleteSection = (i) =>{
        setSections(sections?.filter((a,index) => index !== i))
        setRender(render+1)
    }
    const handleSectionValues = (key,value,sectionId,) =>{
        const s = sections?.map((a,i) => {
            if(i !== sectionId) return a;
            return {...a,[key]: value}
        });
        setSections(s);
        setRender(render+1);
    }
    const handleListValues = (key,value,sectionId,listId) =>{
        const s = sections?.map((a,i) => {
            if(i !== sectionId) return a;
            return {...a, list: a?.list?.map((b, j) => j === listId ? ({...b, [key]: value}): b)}
        });
        setSections(s);
        setRender(render+1);
    }

    return  <>
        <Grid.Col span={6}>
        {["Url"].includes(msgType) && <>
            <Grid.Col span={12}>
                <Page.GroupGap style={{gap: 5}}>
                    <TextView.Bold size={"md"}  text={"Header"} />
                    <Select size={"xs"} data={["NONE","TEXT","IMAGE","VIDEO","DOCUMENT"]} value={headerType}  onChange={setHeaderType}/>
                </Page.GroupGap>
                {headerType === "TEXT" && <Inputs.Text label={"Header Message"} value={headerText} onChange={setHeaderText} limit={60}  error={isError && headerText?.trim() === "" ? "Message is Required For Text Type": ""}  />}
            </Grid.Col>
            {!["NONE","TEXT"]?.includes(headerType) &&  <DocumentPickView msgType={headerType} file={headerFile} setFile={setHeaderFile} isLink={true} />}
        </>}
        {["List"].includes(msgType) && <Grid.Col span={12}>
            <Inputs.Text label={"Header Message"} value={headerText} onChange={setHeaderText} limit={60}  error={isError && headerText?.trim() === "" ? "Message is Required": ""}  />
        </Grid.Col>}
        <Grid.Col span={12}>
            <TextView.TextAreaEditor text={body} setText={setBody} limit={4096} error={isError && body?.trim() === "" ? "Body text is Required": ""}  />
        </Grid.Col>
        <Grid.Col span={12}>
            <Inputs.Text label={"Footer Message"} value={footer} onChange={setFooter} limit={60} error={isError && body?.trim() === "" ? "Body text is Required": ""}  />
        </Grid.Col>
        <Grid.Col span={12}>
            <Inputs.Text required label={"Button Text"} value={btnText} onChange={setBtnText} limit={20} error={isError && btnText?.trim() === "" ? "Button text is Required": ""} />
        </Grid.Col>
        {["Url"].includes(msgType) && <>
            <Grid.Col span={12}>
                <Inputs.Text required label={"Button Url"} value={btnUrl} onChange={setBtnUrl} error={isError && btnUrl?.trim() === "" ? "Button Url is Required": ""} />
            </Grid.Col>
        </>}
        {["List"].includes(msgType) && <>
            <Grid.Col span={12}>
                <Stack sx={{gap: 3}}>
                    {errors?.map((a,i) => <TextView.Error text={`${i+1}. ${a}`} align="left" size="xs" />)}
                </Stack>
            </Grid.Col>
            <Grid.Col span={12}>
                <Group>
                    <Stack sx={{gap: 3}}>
                        <Text weight={"bold"} size={"md"} variant={"gradient"}>Sections</Text>
                        <Text color={"dimmed"} size={"xs"}>Max. 10 List Items</Text>
                    </Stack>
                    <Buttons.Header label={"Add Section"} onClick={handleSections} mt={15}  />
                </Group>
            </Grid.Col>
            {sections?.map((a,i) =>{
                return <>
                    <Grid.Col span={12}>
                        <Group>
                            <Inputs.Text limit={24} required size={"xs"} label={"Section  Title"} value={a?.title} onChange={(value) => handleSectionValues("title",value,i)} error={isError && a?.title?.trim() === "" ? "Title is Required": ""}  />
                            <Button compact mt={15} onClick={() => handleListItems(i)}>Add List Items</Button>
                            {sections?.length > 1 && <Button compact color={"red"} mt={15} onClick={() => DeleteSection(i)}>Remove Section</Button>}
                        </Group>
                    </Grid.Col>
                    <Grid.Col span={12}>
                        {a?.list?.map((b,j) =>{
                            return <Group>
                                {type !== "sequence" && <Inputs.Text limit={200} required label={"Row  id"} value={b?.id}
                                              onChange={(value) => handleListValues("id", value, i, j)}
                                              error={isError && b?.id?.trim() === "" ? "Id is Required" : ""}/>}
                                <Inputs.Text limit={24} required label={"Row  Title"} value={b?.title} onChange={(value) => handleListValues("title",value,i, j)} error={isError && b?.title?.trim() === "" ? "Title is Required": ""}  />
                                <Inputs.Text limit={72} label={"Row  Description"} value={b?.description} onChange={(value) => handleListValues("description",value,i, j)} />
                                {a?.list?.length > 1 && <Page.Icon icon={"IconTrash"} color={"red"} onClick={() => handleRemoveList(i,j)} />}
                            </Group>
                        })}
                    </Grid.Col>
                    <Grid.Col span={12}><Divider /></Grid.Col>
                </>
            })}
        </>}
        </Grid.Col>
        <Grid.Col span={6}>
            <MessagePreview type={"session"} message={{headerType,headerText, headerFile,body,footer,btnText,btnUrl, type: msgType}} />
 </Grid.Col>
    </>
});
export default InteractiveMessage;