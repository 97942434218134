import {applyMiddleware, combineReducers, createStore} from 'redux';
import {persistReducer} from 'redux-persist';
import ReduxThunk from 'redux-thunk';
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

import userReducer from './reducer/user';
import active from './reducer/active';

const persistConfig = {
    key: 'root',
    storage,
};
const rootReducer = combineReducers({
    user: userReducer,
    active
});
const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = createStore(persistedReducer, applyMiddleware(ReduxThunk));
export default store;
