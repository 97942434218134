import {Colors} from "../_Constants";
import {
    Group,
    Loader,
    Modal,
    Paper,
    Stack,
    Text,
    Badge,
    Tooltip,
    ActionIcon,
    Drawer, ScrollArea, Grid, Pagination, Table, Image, Container, Menu, Tabs, Indicator,
} from "@mantine/core";
import React, {useState} from "react";
import {Buttons, TextView} from "./index";
import { useSelector} from "react-redux";
import {IconArrowsSort, IconColorFilter, IconDotsVertical} from "@tabler/icons-react";
import {getIcons} from "../_Constants/Functions";
import {useNavigate} from "react-router-dom";
import {IconDiscountCheck} from "@tabler/icons";

export const getValidBadge = ({valid,error}) =>{
    return valid ? <IconDiscountCheck color={'green'} /> : error ? <Badge color={'red'} >Required</Badge>: <div />;
}

export const Loading = ({isLoading,children}) =>{
    return <>
        {isLoading ?
                <Loader type={"bars"} style={(theme) => ({ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"})} />:
        <>{children}</>}
        </>
}
export const ModalSetup = ({title, onClose, ...props}) =>{
    return <Modal shadow={"md"}  opened={true} title={<TextView.Bold size={"md"} text={title}  />} onClose={onClose} {...props}>
        <children {...props}/>
    </Modal>
}
export const DrawerSetup = ({title, onClose,children, ...props}) =>{
    return <Drawer  styles={{
        body: {
            margin: "0px",
            padding: "0px",
        },
    }} component={ScrollArea} position={"right"} shadow={"md"} withCloseButton={false} opened={true} onClose={onClose} {...props}>
        {title && <GroupGap bg={Colors.Primary} p={5} px={10}>
            <TextView.Header c={"white"} text={title}/>
        </GroupGap>}
        <Stack mx={5} my={10}>
            {children}
        </Stack>
    </Drawer>
}
export const ListRender = ({data,component,noData , noDataComp}) =>{
    return <Grid>
        {data?.length > 0 ?<>
            {data?.map(a => component(a))}
            </>: <Grid.Col span={12} align={"center"}>
            {noDataComp ? noDataComp() :  <NoData label={noData}/>}
        </Grid.Col>}
    </Grid>
}
export const TableRender = ({headers,data,component,noData , noDataComp}) =>{

   return <Table striped={"even"} highlightOnHover>
        <Table.Thead>
            <Table.Tr style={{backgroundColor: Colors.Primary, color: "white"}}>{headers?.map(a => <Table.Th>{a}</Table.Th>)}</Table.Tr>
        </Table.Thead>
        <Table.Tbody>
            {data?.length > 0 ?<>
                {data?.map((a,i) => component(a,i))}
            </>: <Table.Tr>
                <Table.Td colspan={headers?.length} bg={Colors.PrimaryLow} align={"center"}>
                    <TextView.Header text={noData} />
                </Table.Td>
            </Table.Tr>}
        </Table.Tbody>
    </Table>
}
export const Header = ({title, subTitle, buttons,link , pref}) =>{
    const {alias} = useSelector(state => state.active);
    const navigation = useNavigate();
    return  <Paper px={10} shadow={"lg"} style={{borderBottomStyle: "solid", borderBottomWidth: 1, transition: "box-shadow 150ms ease"}} p={3}>
            <Group  justify="space-between">
                <Stack style={{gap: 1}} mb={5}>
                    <Group justify={'flex-start'} align={'center'} gap={"sm"}>
                        {link && <TextView.Header style={{cursor: "pointer"}}  text={`${link?.name} / `} fw={700} onClick={() => navigation(`/device/${alias}/${link?.link}`)} />}
                        <TextView.Header  text={title} fw={700} />
                        {pref?.total && pref?.used && <PrefView used={pref?.used} total={pref?.total} />}
                    </Group>
                    <TextView.Small visibleFrom={"md"} text={subTitle} />
                </Stack>
                <Group visibleFrom={"md"}>
                    {buttons?.map(a =>  <Buttons.Header {...a}  />)}
                </Group>
                {/*<Group>*/}
                {/*    {buttons?.map(a =>  <Icon icon={a?.icon} label={a?.label} />)}*/}
                {/*</Group>*/}
            </Group>
        </Paper>
}
const PrefView = ({used, total}) =>{
     const percent = (100 * used) / total;
    return <ToolTip label={`You have used ${used} out of ${total} from your available limit in this plan`}>
        <Badge variant={"light"} color={percent > 90 ? "red" : percent > 75 ? "orange":"green"} >{`${used} / ${total}`}</Badge>
    </ToolTip>
}


export const BadgeView  = ({label, color}) => <Badge variant={"light"} color={color}>{label}</Badge>
export const Icon = ({icon,onClick,label,color = Colors.Primary,variant,outerColor,size, iconProps}) =>{
    const props = color ? {color,...iconProps}: {...iconProps};
    return <ToolTip label={label}>
        {icon && <ActionIcon variant={variant || 'subtle'} size={size || 'sm'} onClick={onClick} color={outerColor || color}>
            {getIcons(icon, props)}
        </ActionIcon>}
    </ToolTip>
}
export const LoadingIcon = ({isLoading, children}) =>{
    return <>
        {isLoading ? <Loader size={"xs"} type={"bars"} align={"center"} />: children}
    </>

}
export const IconText = ({icon,iconProps,label,text,onClick,color, textProps}) =>{
    return <ToolTip label={label}>
        <Group mb={3} mx={5} style={{gap: 3,cursor: "pointer"}} onClick={()=>onClick()}>
            {getIcons(icon, {size: 14,...iconProps})}
            <Text size={"sm"} c={color || "black"} {...textProps}>{text}</Text>
        </Group>
    </ToolTip>
}
export const ToolTip = ({label ,children}) =>{
    return label ?<Tooltip  withArrow={false} offset={2} style={{ backgroundColor: '#ccc', backdropFilter: 'blur(20px)', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.9)'}}
                            label={<TextView.Bold text={label} c={Colors.Primary} />}
                            children={children} /> : children;
}
export const AlertPopup = ({opened,onClose,title,content,successTitle,cancelTitle,onSuccess,hideCancel, hideLoading}) =>{
    const [loading,setLoading] = useState(false);
    const handleClose = () =>{
        if(onClose) {
            onClose()
        }else{
            // dispatch(ActionHidePopup());
        }
    }
    const handleSuccess = () =>{
        if(!hideLoading) setLoading(true);
        onSuccess();
    }
    if(loading && opened === false) setLoading(false);

    return <ModalSetup onClose={handleClose} title={title} bg={Colors.Primary}>
        <Stack bg={"red"}>
            <TextView.Paragraph text={content} size={"md"} />
            <Group my={10} justify={"flex-end"}>
                {!hideCancel && <Buttons.Header onClick={handleClose} bg={'gray'} size={'xs'} label={cancelTitle} />}
                <Buttons.Header bg={'green'}  size={'xs'} onClick={handleSuccess} loading={loading} label={successTitle} />
            </Group>
        </Stack>
    </ModalSetup>
}
export const PaginationFooter = ({total, page, setPage, perPage}) =>{
    return <Group justify={"center"} py={10}>
        <TextView.Bold text={`Showing ${Math.min(total, ((page-1) * perPage) + 1)} - ${Math.min((page * perPage), total)} of ${total}`} />
        {total > perPage && <Pagination total={Math.ceil(total / perPage)} value={page} size={"xs"} onChange={setPage}/>}
    </Group>
}
export const PrefCountView = ({type}) => {
    const {prefrences,prefUsed} = useSelector(state => state.active);
    return <Paper shadow={"md"} withBorder px={10}>
            <Group>
                <Icon icon={"IconMessageChatbot"} />
                <TextView.Small text={`${prefUsed?.[type]} / ${prefrences?.[type]} ${type}`}/>
            </Group>
        </Paper>
}
export const NoData = ({icon,label}) =>{
    return  <Container size={500}>
            <Image src={require("../_Constants/images/noData.webp")} style={{width: "400px", height: "400px"}}   />
            <TextView.Header size={"xl"}  text={label || "No Data Available"} />
         </Container>
}
export const TableView = ({headers,children}) =>{
    return <Table striped={"even"}>
        <Table.Thead>
             <Table.Tr style={{backgroundColor: Colors.Primary, color: "white"}}>{headers?.map(a => <Table.Th>{a}</Table.Th>)}</Table.Tr>
        </Table.Thead>
        <Table.Tbody>{children}</Table.Tbody>
    </Table>
}
export const Label = ({label,value,limit,required}) =>{
    return <Group justify={"space-between"} style={{width: "100%"}}>
        <Group style={{gap:3} }>
            <Text size={"xs"} fw={"600"}>{label}</Text>
            {required && <TextView.Bold text={"*"} c={"red"} />}
        </Group>
        {limit && <Text size={"xs"} c={value?.length > limit ? "red": "dimmed"}>{value?.length} / {limit}</Text>}
    </Group>
}
export const GroupApart = ({children,...props}) =>{
    return <Group justify={"space-between"}  gap={"xs"}  {...props}>
        {children}
    </Group>
}
export const GroupCenter = ({children, ...props}) =>{
    return <Group justify={"center"}  gap={"xs"} {...props}>
        {children}
    </Group>
}
export const GroupGap = ({children,...props}) =>{
    return <Group gap={"xs"} {...props}>
        {children}
    </Group>
}
export const Card = ({children,style = {}, ...props}) =>{
    return <Paper shadow={"md"} withBorder p={5} style={{borderRadius: 5,...style}} {...props}>
        {children}
    </Paper>
}
export const MenuList = ({list}) =>{
    return   <Menu shadow="md" width={200}>
        <Menu.Target>
            <Group style={{cursor: "pointer"}}>
                <IconDotsVertical size={16} />
            </Group>
        </Menu.Target>
        <Menu.Dropdown style={{border: "2px solid", borderColor: Colors.Primary}} gap={"xs"}>
            {list?.map(a => <Menu.Item
                leftSection={<Icon size={"xs"} icon={a?.icon} color={a?.iconColor || "black"} />}
                onClick={a?.onClick}
            >
                {a?.label}
            </Menu.Item>)}
        </Menu.Dropdown>
    </Menu>
}
export const Sort = ({list, onClick,selected}) =>{
    return   <Menu shadow="md" width={200}>
        <Menu.Target>
            <Indicator disabled={!selected} color={"red"}>
                <ActionIcon size={"sm"} variant={"outline"} style={{cursor: "pointer"}} align={"center"}>
                    <IconArrowsSort />
                </ActionIcon>
            </Indicator>
        </Menu.Target>
        <Menu.Dropdown style={{border: "2px solid", borderColor: Colors.Primary}}>
            {list?.map(a => <Menu.Item
                bg={selected === a?.value ? Colors.Primary: "white"}
                c={selected === a?.value ? "white": "black"}
                onClick={() => onClick(a?.value)}
            >
                {a?.label}
            </Menu.Item>)}
            <Menu.Divider />
            <Menu.Item  color="red" onClick={() => onClick()}>
                Reset Sort
            </Menu.Item>
        </Menu.Dropdown>
    </Menu>
}
export const Filter = ({}) =>{
    return   <Menu shadow="md" width={200}>
            <Menu.Target>
                <Indicator>
                    <ActionIcon style={{cursor: "pointer"}}>
                        <IconColorFilter />
                    </ActionIcon>
                </Indicator>
            </Menu.Target>
        <Menu.Dropdown style={{border: "1px solid"}}>
            <Menu.Label>Application</Menu.Label>
            <Menu.Item>
                Settings
            </Menu.Item>
            <Menu.Item>
                Messages
            </Menu.Item>
            <Menu.Item>
                Gallery
            </Menu.Item>
            <Menu.Item
                rightSection={
                    <Text size="xs" c="dimmed">
                        ⌘K
                    </Text>
                }
            >
                Search
            </Menu.Item>

            <Menu.Divider />

            <Menu.Label>Danger zone</Menu.Label>
            <Menu.Item
            >
                Transfer my data
            </Menu.Item>
            <Menu.Item
                color="red"
            >
                Delete my account
            </Menu.Item>
        </Menu.Dropdown>
    </Menu>
}

export const ConditionRender = ({isValid, success, fail}) =>{
    return <>
        {isValid ? success : fail}
        </>
}


export const ListApart = ({leftText,rightText, leftWidth,rightWidth, children}) =>{
    return <GroupApart>
        <GroupGap style={{width: leftWidth || "90%"}}>
            <TextView.Small lineClamp={1} text={leftText} />
        </GroupGap>
        <GroupGap style={{width: rightWidth || "5%"}}>
            {rightText && <TextView.Small text={rightText}/>}
            {children}
        </GroupGap>
    </GroupApart>
}

export const TabView = ({list, defaultValue}) =>{
    return  <Tabs defaultValue={defaultValue}  keepMounted={false} >
        <Tabs.List grow>
            {list?.map(a =>  <Tabs.Tab value={a?.value} icon={<Icon icon={a?.icon} />}>{a?.label}</Tabs.Tab>)}
        </Tabs.List>
        {list?.map(a => <Tabs.Panel value={a?.value} pt="xs">
           {a?.component}
        </Tabs.Panel>)}
    </Tabs>
}

