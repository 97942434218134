import React, {useEffect} from "react";
import socketIOClient from "socket.io-client";
import { UpdateActiveSession, UpdatePrefUsed} from "../_Store/action";
import {useDispatch, useSelector} from "react-redux";

var socket;
const SocketSetup = () =>{
    const {userId,sessionId} = useSelector(state => state.active);
    const dispatch = useDispatch();
    useEffect(()=>{
        socket = socketIOClient(`https://wba2.businesssalesmachine.com`, {
            transports: ["websocket"],
            autoConnect: true,
            auth:{
                token: sessionId
            },
            query:{userId}
        });
        if(userId && sessionId){
            socket.on(`UserInfo`,handleInfo);
            socket.emit("UserInfo",{userId,sessionId});
        }
       return () =>{
            socket.off("UserInfo")
        }
    },[userId,sessionId]);


   const  handleInfo = ({prefUsed, ...data}) =>{
       dispatch(UpdateActiveSession(data));
       dispatch(UpdatePrefUsed(prefUsed));

    }
    return <></>
}

export {SocketSetup,socket}
