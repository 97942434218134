import {Grid, Group} from "@mantine/core";
import React, {useEffect, useState} from "react";
import DocumentPicker from "./DocumentPicker";
import {Buttons, TextView} from "../index";

const DocumentPickView = ({file,setFile,msgType}) =>{
    const[isOpen,setOpen]= useState(false);
    useEffect(() => {
        setFile();
    }, [msgType]);
    return <>
        {isOpen && <DocumentPicker msgType={msgType} onChange={setFile} onClose={() => setOpen(false)} details={file} />}
        <Grid.Col span={12}>
            <Group>
                <Buttons.Header onClick={() => setOpen(true)} label={`Choose ${msgType}`} />
                {file?.label && <TextView.Bold text={file?.label}/>}
            </Group>
        </Grid.Col>
     </>
}
export default DocumentPickView;