import {AppShellFooter, AppShellHeader, ScrollArea, Stack} from "@mantine/core";
import {Header} from "./Page";
import InternetStatus from "../_Layouts/InternetChange";
import React, {useEffect} from "react";
import {Page} from "./index";

const PageLayout = ({header,footer,children, isLoading, onStart}) =>{
    useEffect(() => {
        if(onStart) onStart();
    }, []);

    return <Stack spacing={2} height={"100vh"}>
        {header && <AppShellHeader>
            <Header {...header} />
        </AppShellHeader>}
        {/*//bg={"rgba(211, 211, 211, 0.4)"}*/}
        <ScrollArea h={"99vh"} scrollbars={"y"}>
            <Page.Loading isLoading={isLoading}>
                <Stack style={{gap: 3}} mt={header ? 60: 10} mb={footer ? 80: 20} px={5}>
                    <InternetStatus />
                    {children}
                </Stack>
            </Page.Loading>
        </ScrollArea>
        {footer && <AppShellFooter>{footer()}</AppShellFooter>}
    </Stack>
}

export default PageLayout;
